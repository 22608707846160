<template>
  <section class="about-us">
    <h2>{{ $t('about-us.aboutTitle') }}</h2>
    <p>{{ $t('about-us.aboutDescription') }}</p>
  </section>

  <section class="about-us t2">
    <h2>{{ $t('about-us.missionTitle') }}</h2>
    <p>{{ $t('about-us.missionDescription') }}</p>
  </section>

  <section class="about-us t2">
    <h2>{{ $t('about-us.philosophyTitle') }}</h2>
    <p>{{ $t('about-us.philosophyDescription') }}</p>
  </section>

  <section class="about-us t2">
    <h2>{{ $t('about-us.teamTitle') }}</h2>
    <p>{{ $t('about-us.teamDescription') }}</p>
  </section>

  <section class="why-choose-us">
    <h2>{{ $t('about-us.whyChooseUs') }}</h2>
    <div class="reasons-grid">
      <div class="reason-item" @click="navigateTo('/')">
        <img src=".././assets/only_fresh.png" :alt="$t('about-us.freshIngredients')" />
        <h3>{{ $t('about-us.freshIngredients') }}</h3>
      </div>
      <div class="reason-item" @click="navigateTo('/sales')">
        <img src=".././assets/get_deal.png" :alt="$t('about-us.specialOffers')" />
        <h3>{{ $t('about-us.specialOffers') }}</h3>
      </div>
      <div class="reason-item" @click="navigateTo('/#application-section')">
        <img src=".././assets/mobile_app.png" :alt="$t('about-us.mobileApps')" />
        <h3>{{ $t('about-us.mobileApps') }}</h3>
      </div>
    </div>
  </section>

  <section class="about-us t2">
    <h2>{{ $t('about-us.servicesTitle') }}</h2>
    <p>{{ $t('about-us.servicesDescription') }}</p>
  </section>

  <section class="about-us t2">
    <h2>{{ $t('about-us.invitationTitle') }}</h2>
    <p>{{ $t('about-us.invitationDescription') }}</p>
  </section>
</template>

<script>
export default {
  name: "About-us",
  props: {
    msg: String,
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  },
  methods: {
    navigateTo(article) {
      this.$router.push(article)
    }
  }
};
</script>

<style scoped>
.about-us {
  text-align: left;
  padding: 20px;
  margin-top: 40px;
  padding-left: 10%;
  padding-right: 10%;
}
.t2{
  margin-top: 10px !important;
  padding-top: 0px;
}
.about-us h2,
.why-choose-us h2 {
  color: #e9e9e9;
  margin-bottom: 20px;
}

.about-us p {
  max-width: 600px;
  /* margin: 0 auto; */
  color: #e9e9e9;
  text-align: left;
}

.why-choose-us {
  padding: 20px;
  padding-left: 10%;
  padding-right: 10%;
  text-align: left;
}

.why-choose-us .reasons-grid {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.reason-item {
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100%;
  max-width: 300px;
}

.reason-item img {
  width: 100%;
  height: auto;
}

.reason-item h3 {
  padding: 10px;
  color: #e9e9e9;
}

@media (max-width: 768px) {
  .about-us p,
  .why-choose-us h2,
  .reason-item h3 {
    font-size: 16px;
  }

  .why-choose-us .reasons-grid {
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .why-choose-us .reasons-grid {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
</style>