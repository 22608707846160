<template>
  <div class="divs-ce">
    <div class="sushi-itemm-container">
      <div v-if="sushi" class="sushi-itemm">
        <div class="sushi-images">
          <img v-if="sushi && sushi.photo" class="main-image" :src="sushi.photo" :alt="$t('sushi.name', { name: sushi.name })" />
        </div>
        <div class="sushi-details">
          <p class="item-id">{{ sushi.article }}</p>
          <h2>{{ $t('sushi.name', { name: sushi.name }) }}</h2>
          <p v-if="sushi.weight" class="item-weight">{{ sushi.weight }} {{ $t('sushi.weightUnit') }}</p>
          <p class="item-description">{{ $t('sushi.description', { description: sushi.description }) }}</p>
          <p class="item-price">{{ sushi.price }} {{ $t('sushi.currency') }}</p>
          <div class="add-to-cart-container">
            <div class="add-to-cart" @click="addToCart(sushi)">
              <button class="cart-button-it">{{ $t('buttons.addToCart') }}</button>
            </div>

            <img 
              v-if="isFavorite(sushi.article)" 
              class="favorite-icon" 
              src="../assets/icons/mdi_heart_full.png" 
              :alt="$t('icons.removeFromFavorites')" 
              @click="removeFromFavorite(sushi.article)" 
            />

            <img 
              v-else 
              class="favorite-icon" 
              src="../assets/icons/mdi_heart.png" 
              :alt="$t('icons.addToFavorites')" 
              @click="addToFavorite(sushi.article)" 
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="showLoginPopup" class="login-popup">
      <h2>{{ $t('loginpopup.popupTitle') }}</h2>
      <button @click="redirectToLogin">{{ $t('buttons.login') }}</button>
    </div>
  </div>

  <section class="sushi-section" id="section-krab">
    <h2>{{ $t('sushi.recommendationTitle') }}</h2>
    <div class="sushi-grid">
      <div 
        class="sushi-item" 
        v-for="item in filteredSushiList(['5009','5002'])" 
        :key="item.article" 
        @click="navigateToSushiItem(item.article)"
      >
        <img :src="item.photo" :alt="$t('sushi.name', { name: item.name })" />
        <h3>{{ $t('sushi.name', { name: item.name }) }}</h3>
        <span class="article-number">{{ item.article }}</span>
        <p>{{ $t('sushi.description', { description: item.description }) }}</p>
        <div class="button-container">
          <span class="price">{{ item.price }} {{ $t('sushi.currency') }}</span>
          <button @click.stop="addToCart(item)">
            {{ $t('buttons.want') }}
            <img src="../assets/plus_icon.png" :alt="$t('icons.plusIcon')" />
          </button>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import axios from 'axios';
export const sushiList = [
       {
        article: '5009',
        name: 'Рол Lovely',
        weight: '320',
        description: 'Рис, крем-сир, краб, соус шрірача, лосось, кумкват',
        price: '329',
        photo: '/img/sushi/5009_front.jpeg',
        category: '2'
      },
      {
        article: '5002',
        name: 'Рол чіз з крабом',
        weight: '350',
        description: 'Рис, чорнило каракатиці, чеддeр, крем-сир, краб, манго, унагі, ікра тобіко',
        price: '289',
        photo: '/img/sushi/5002_front.jpeg',
        category: '2'
      },
      {
        article: '5005',
        name: 'Каліфорнія з лососем',
        weight: '330',
        description: 'Рис, огірок, авокадо, лосось, ікра тобіко, манго, соус спайсі , солодкий чилі',
        price: '339',
        photo: '/img/sushi/5005_front.jpeg',
        category: '1'
      },
      {
        article: '5016',
        name: 'Філадельфія з лососем',
        weight: '320',
        description: 'Рис, крем-сир, огірок, авокадо, лосось',
        price: '269',
        photo: '/img/sushi/5016_front.jpeg',
        category: '1'
      },
      {
        article: '5011',
        name: 'Макі з лососем і вугрем',
        weight: '250',
        description: 'Рис, норі, лосось, вугор, крем-сир, огірок, манго, авокадо, соус унагі, соус спайсі',
        price: '259',
        photo: '/img/sushi/5011_front.jpeg',
        category: '1'
      },
      {
        article: '5008',
        name: 'Червоний дракон',
        weight: '360',
        description: 'Рис, норі, лосось, огірок, авокадо, ікра тобіко, томаго, зелена цибуля, креветка',
        price: '365',
        photo: '/img/sushi/5008_front.jpeg',
        category: '1'
      },
      {
        article: '5003',
        name: 'Спрінг рол з лососем',
        weight: '230',
        description: 'Рисовий папір, лосось, крем-сир, огірок, пекінська капуста, ікра тобіко, світ чилі, кумкват',
        price: '289',
        photo: '/img/sushi/5003_front.jpeg',
        category: '1'
      },
      {
        article: '5014',
        name: 'Філадельфія з тунцем',
        weight: '320',
        description: 'Рис, норі, крем-сир, огірок, авокадо, тунець',
        price: '279',
        photo: '/img/sushi/5014_front.jpeg',
        category: '3'
      },
      {
        article: '5010',
        name: 'Рол з тунцем',
        weight: '355',
        description: 'Рис, крем-сир, огірок, тунець, манго, світ чилі, унагі, тагараші, кумкват',
        price: '289',
        photo: '/img/sushi/5010_front.jpeg',
        category: '3'
      },
      {
        article: '5007',
        name: 'Золотий дракон',
        weight: '355',
        description: 'Рис, норі, вугор, огірок, авокадо, зелена цибуля, ікра тобіко, соус спайсі, лосось, унагі, томаго',
        price: '439',
        photo: '/img/sushi/5007_front.jpeg',
        category: '4'
      },
      {
        article: '5006',
        name: 'Тигровий дракон',
        weight: '375',
        description: 'Рис, норі, креветка, огірок, авокадо, зелена цибуля, соус спайсі, вугор, томаго, ікра тобіко',
        price: '415',
        photo: '/img/sushi/5006_front.jpeg',
        category: '4'
      },
      {
        article: '5013',
        name: 'Філадельфія з вугрем',
        weight: '320',
        description: 'Рис, норі, крем-сир, огірок, авокадо, вугор',
        price: '309',
        photo: '/img/sushi/5013_front.jpeg',
        category: '4'
      },
      {
        article: '5012',
        name: 'Смажений з креветкою',
        weight: '260',
        description: 'Рис, норі, крем-сир, креветка, моцарела, ікра тобіко, кляр панко, соус спайсі',
        price: '250',
        photo: '/img/sushi/5012_front.jpeg',
        category: '5'
      },
      {
        article: '5015',
        name: 'Філадельфія з креветкою',
        weight: '320',
        description: 'Рис, норі, крем-сир, огірок, авокадо, креветка',
        price: '335',
        photo: '/img/sushi/5015_front.jpeg',
        category: '5'
      },
      {
        article: '5004',
        name: 'Сет гунканів',
        weight: '570',
        description: 'Рис, норі, лосось, тунець, вугор, краб, ікра тобіко, соус спайсі, соус світ чилі',
        price: '730',
        photo: '/img/sushi/5004_front.jpeg',
        category: '6'
      },
      {
        article: '5001',
        name: 'Рол Снікерс',
        weight: '255',
        description: 'Крем-сир, Нутелла, банан, топінг полуниця, полуниця',
        price: '279',
        photo: '/img/sushi/5001_front.jpeg',
        category: '7'
      },
        {
          article: '5040',
          name: 'Напій Caycen SAGIKO 320 мл',
          weight: '',
          description: '',
          price: '120',
          photo: '/img/sushi/5040_front.png',
          category: '12'
        },
        {
          article: '5041',
          name: 'Напій Тамаринд SAGIKO 320 мл',
          weight: '',
          description: '',
          price: '120',
          photo: '/img/sushi/5041_front.png',
          category: '12'
        },
        {
          article: '5042',
          name: 'Напій Маракуйя SAGIKO 320 мл',
          weight: '',
          description: '',
          price: '120',
          photo: '/img/sushi/5042_front.png',
          category: '12'
        },
        {
          article: '5043',
          name: 'Напій Рожева Гуава SAGIKO 320 мл',
          weight: '',
          description: '',
          price: '120',
          photo: '/img/sushi/5043_front.png',
          category: '12'
        },
        {
          article: '5044',
          name: 'Напій Манго SAGIKO 320 мл',
          weight: '',
          description: '',
          price: '120',
          photo: '/img/sushi/5044_front.png',
          category: '12'
        },
        {
          article: '5045',
          name: 'Пепсі 0.33 скло',
          weight: '',
          description: '',
          price: '60',
          photo: '/img/sushi/5045_front.png',
          category: '12'
        },
        {
          article: '5046',
          name: 'Пепсі чорна 0.25 скло',
          weight: '',
          description: '',
          price: '60',
          photo: '/img/sushi/5046_front.png',
          category: '12'
        },
        {
          article: '5047',
          name: '7up 0.25 скло',
          weight: '',
          description: '',
          price: '50',
          photo: '/img/sushi/5047_front.png',
          category: '12'
        },
        {
          article: '5048',
          name: 'Mirinda 0.25 скло',
          weight: '',
          description: '',
          price: '50',
          photo: '/img/sushi/5048_front.png',
          category: '12'
        },
        {
          article: '5049',
          name: 'Карпатська Джерельна газ 0.5 скло',
          weight: '',
          description: '',
          price: '100',
          photo: '/img/sushi/5049_front.png',
          category: '12'
        },
        {
          article: '5050',
          name: 'Карпатська Джерельна н/газ 0.5 скло',
          weight: '',
          description: '',
          price: '100',
          photo: '/img/sushi/5050_front.png',
          category: '12'
        },
        {
          article: '5051',
          name: 'Сік Сандора апельсин 0.5',
          weight: '',
          description: '',
          price: '80',
          photo: '/img/sushi/5051_front.png',
          category: '12'
        },
        {
          article: '5052',
          name: 'Сік Сандора мультивітамін 0.5',
          weight: '',
          description: '',
          price: '80',
          photo: '/img/sushi/5052_front.png',
          category: '12'
        },
        {
          article: '5053',
          name: 'Сік Сандора томат 0.5',
          weight: '',
          description: '',
          price: '80',
          photo: '/img/sushi/5053_front.png',
          category: '12'
        },
        {
          article: '5054',
          name: 'Сік Сандора яблучний 0.5',
          weight: '',
          description: '',
          price: '80',
          photo: '/img/sushi/5054_front.png',
          category: '12'
        },
        {
          article: '5061',
          name: 'Креветка попкорн',
          weight: '162',
          description: 'Креветка, борошно, олія соняшникова, розпушувач, соус солодкий чилі, соус унагі',
          price: '170',
          photo: '/img/sushi/5061_front.jpeg',
          category: '8'
        },
        {
          article: '5062',
          name: 'Фіш боул',
          weight: '300',
          description: 'Лосось, цибуля синя, яйце куряче, панко, олія соняшникова, борошно, соус спайсі, соус унагі',
          price: '280',
          photo: '/img/sushi/5062_front.jpeg',
          category: '8'
        },
        {
          article: '5063',
          name: 'Тяхан з лососем',
          weight: '350',
          description: 'Рис, болгарський перець, морква, цибуля синя, цибуля зелена, огірок, кунжут, яйце куряче, соус солодкий чилі, соус унагі, соєвий соус, устричний соус, лосось',
          price: '280',
          photo: '/img/sushi/5063_front.jpeg',
          category: '8'
        },
        {
          article: '5064',
          name: 'Фунчоза з креветкою',
          weight: '330',
          description: 'Локшина фунчоза, цибуля синя, морква, перець болгарський, цибуля зелена, огірок, кунжут, соєвий соус, соус унагі, соус солодкий чилі, креветка',
          price: '330',
          photo: '/img/sushi/5064_front.jpeg',
          category: '8'
        },
        {
          article: '5065',
          name: 'Локшина соба з лососем',
          weight: '330',
          description: 'Локшина соба, перець болгарський, цибуля синя, морква, цибуля зелена, огірок, соус унагі, соєвий соус, соус солодкий чилі, лосось',
          price: '280',
          photo: '/img/sushi/5065_front.jpeg',
          category: '8'
        },

        {
          article: '5066',
          name: 'Футомакі з лососем',
          weight: '240',
          description: 'Рис, норі, крем-сир, огірок, лосось, соус спайсі',
          price: '215',
          photo: '/img/sushi/5066_front.jpeg',
          category: '1'
        },

        {
          article: '5067',
          name: 'Тяхан з креветкою',
          weight: '350',
          description: 'Рис, перець болгарський, морква, цибуля синя, цибуля зелена, огірок, кунжут, яйце куряче, соус солодкий чилі, соус унагі, соєвий соус, устричний соус, креветка',
          price: '270',
          photo: '/img/sushi/5067_front.jpeg',
          category: '8'
        },
        {
          article: '5068',
          name: 'Фунчоза з лососем',
          weight: '330',
          description: 'Локшина фунчоза, цибуля синя, морква, перець болгарський, цибуля зелена, огірок, кунжут, соєвий соус, соус унагі, соус солодкий чилі, лосось',
          price: '330',
          photo: '/img/sushi/5068_front.jpeg',
          category: '8'
        },
        {
          article: '5069',
          name: 'Локшина соба з креветкою',
          weight: '330',
          description: 'Локшина соба, перець болгарський, цибуля синя, морква, цибуля зелена, огірок, соус унагі, соєвий соус, соус солодкий чилі, креветка',
          price: '280',
          photo: '/img/sushi/5069_front.jpeg',
          category: '8'
        },
        {
          article: '5060',
          name: 'Вогняний дракон',
          weight: '370',
          description: 'Рис, норі, манго, огірок, крем-сир, лосось, унагі, вугор, тобіко, перець чилі',
          price: '420',
          photo: '/img/sushi/5060_front.jpeg',
          category: '1'
        },
        {
          article: '5070',
          name: 'Сет Токаш',
          weight: '1250',
          description: 'Рол з тунцем, спрінг рол з лососем, макі рол з огірком, каліфорнія з вугрем, тобіко, нігірі, футомакі з лососем, імбир, васабі',
          price: '1159',
          photo: '/img/sushi/5070_front.jpeg',
          category: '13'
        },
        {
          article: '5071',
          name: 'Сет Філадельфія мікс',
          weight: '720',
          description: 'Рол філадельфія мікс: лосось та тунець + Рол філадельфія мікс: креветка та вугор + Рол філадельфія з авокадо',
          price: '780',
          photo: '/img/sushi/5071_front.jpeg',
          category: '13'
        },
        {
          article: '5072',
          name: 'Рол Токаш',
          weight: '480',
          description: 'Рис, Норі, Крем-сир, Креветка, Яйце куряче, Панко, Огірок, Краб, Лосось, Ікра Тобіко, Кумкват, Устричний соус',
          price: '420',
          photo: '/img/sushi/5072_front.jpeg',
          category: '1'
        },
        {
          article: '5073',
          name: 'РолДог',
          weight: '460',
          description: 'Рис, норі, крем-сир, тостовий сир, огірок, креветка, крабова паличка, соус спайсі, соус унагі, панко, яйце куряче, борошно, олія соняшникова',
          price: '315',
          photo: '/img/sushi/5073_front.jpeg',
          category: '5'
        },
        {
          article: '5074',
          name: 'Рол від Шефа',
          weight: '430',
          description: 'Рис, норі, креветка, крем-сир, томаго, вугор, соус унагі, майонез, трюфельна сальса, стружка кокоса, огірок',
          price: '420',
          photo: '/img/sushi/5074_front.jpeg',
          category: '4'
        },
        {
          article: '5075',
          name: 'Рол Баунті',
          weight: '290',
          description: 'Тісто листове, крем-сир, кокосова стружка, пудра сахарна, банан, карамельний топінг',
          price: '239',
          photo: '/img/sushi/5075_front.jpeg',
          category: '7'
        },
         {
          article: '5078',
          name: 'Лимонад "Зандукелі" Груша 0.5 л',
          weight: '',
          description: '',
          price: '100',
          photo: '/img/sushi/5078_front.jpeg',
          category: '12'
        },
        {
          article: '5079',
          name: 'Лимонад "Зандукелі" Сапераві 0.5 л',
          weight: '',
          description: '',
          price: '100',
          photo: '/img/sushi/5079_front.jpeg',
          category: '12'
        },
        {
          article: '5080',
          name: 'Лимонад "Зандукелі" Тархун 0.5 л',
          weight: '',
          description: '',
          price: '100',
          photo: '/img/sushi/5080_front.jpeg',
          category: '12'
        },
        {
          article: '5081',
          name: 'Смажений с лососем',
          weight: '260',
          description: 'Рис, сир Чедар, лосось, крем сир, норі, соус Спайсі',
          price: '250',
          photo: '/img/sushi/5081_front.jpeg',
          category: '1'
        },
        {
          article: '5082',
          name: 'Рол без рису',
          weight: '220',
          description: 'Норі, лосось, тунець, креветка, авокадо, огірок',
          price: '489',
          photo: '/img/sushi/5082_front.jpeg',
          category: '3'
        },
        {
          article: '5083',
          name: 'Сет Класік',
          weight: '1400',
          description: 'Філадельфія з лососем, Футомакі з креветкою, Каліфорнія з вугрем, Ро чеддер, Макі з тунцем, Макі з авокадо і манго, Нігірі з лососем',
          price: '850',
          photo: '/img/sushi/5083_front.jpeg',
          category: '13'
        },
        {
          article: '5084',
          name: 'Темакі',
          weight: '250',
          description: 'Рис, норі, лосось, крем-сир, огірок, тобіко, унагі',
          price: '240',
          photo: '/img/sushi/5084_front.jpeg',
          category: '8'
        },
        {
          article: '5085',
          name: 'Суші Бургер',
          weight: '310',
          description: 'Рис, норі, сир чеддер, крем-сир, тобіко, лосось, креветки, краб, соус спайсі, соус унагі',
          price: '280',
          photo: '/img/sushi/5085_front.jpeg',
          category: '8'
        },
        {
          article: '5083',
          name: 'Сет Класік',
          weight: '1400',
          description: 'Філадельфія з лососем, Футомакі з креветкою, Каліфорнія з вугрем, Ро чеддер, Макі з тунцем, Макі з авокадо і манго, Нігірі з лососем',
          price: '850',
          photo: '/img/sushi/5083_front.jpeg',
          category: '15'
        },
        {
          article: '5084',
          name: 'Темакі',
          weight: '250',
          description: 'Рис, норі, лосось, крем-сир, огірок, тобіко, унагі',
          price: '240',
          photo: '/img/sushi/5084_front.jpeg',
          category: '15'
        },
        {
          article: '5085',
          name: 'Суші Бургер',
          weight: '310',
          description: 'Рис, норі, сир чеддер, крем-сир, тобіко, лосось, креветки, краб, соус спайсі, соус унагі',
          price: '280',
          photo: '/img/sushi/5085_front.jpeg',
          category: '15'
        },
        {
          article: '5555',
          name: 'Пакет',
          weight: '2',
          description: 'Пакет',
          price: '10',
          photo: '/img/sushi/5555_front.jpeg',
          category: '14'
        }
      ];

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      sushiList: [
       {
        article: '5009',
        name: 'Рол Lovely',
        weight: '320',
        description: 'Рис, крем-сир, краб, соус шрірача, лосось, кумкват',
        price: '329',
        photo: '/img/sushi/5009_front.jpeg',
        category: '2'
      },
      {
        article: '5002',
        name: 'Рол чіз з крабом',
        weight: '350',
        description: 'Рис, чорнило каракатиці, чеддeр, крем-сир, краб, манго, унагі, ікра тобіко',
        price: '289',
        photo: '/img/sushi/5002_front.jpeg',
        category: '2'
      },
      {
        article: '5005',
        name: 'Каліфорнія з лососем',
        weight: '330',
        description: 'Рис, огірок, авокадо, лосось, ікра тобіко, манго, соус спайсі , солодкий чилі',
        price: '339',
        photo: '/img/sushi/5005_front.jpeg',
        category: '1'
      },
      {
        article: '5016',
        name: 'Філадельфія з лососем',
        weight: '320',
        description: 'Рис, крем-сир, огірок, авокадо, лосось',
        price: '269',
        photo: '/img/sushi/5016_front.jpeg',
        category: '1'
      },
      {
        article: '5011',
        name: 'Макі з лососем і вугрем',
        weight: '250',
        description: 'Рис, норі, лосось, вугор, крем-сир, огірок, манго, авокадо, соус унагі, соус спайсі',
        price: '259',
        photo: '/img/sushi/5011_front.jpeg',
        category: '1'
      },
      {
        article: '5008',
        name: 'Червоний дракон',
        weight: '360',
        description: 'Рис, норі, лосось, огірок, авокадо, ікра тобіко, томаго, зелена цибуля, креветка',
        price: '365',
        photo: '/img/sushi/5008_front.jpeg',
        category: '1'
      },
      {
        article: '5003',
        name: 'Спрінг рол з лососем',
        weight: '230',
        description: 'Рисовий папір, лосось, крем-сир, огірок, пекінська капуста, ікра тобіко, світ чилі, кумкват',
        price: '289',
        photo: '/img/sushi/5003_front.jpeg',
        category: '1'
      },
      {
        article: '5014',
        name: 'Філадельфія з тунцем',
        weight: '320',
        description: 'Рис, норі, крем-сир, огірок, авокадо, тунець',
        price: '279',
        photo: '/img/sushi/5014_front.jpeg',
        category: '3'
      },
      {
        article: '5010',
        name: 'Рол з тунцем',
        weight: '355',
        description: 'Рис, крем-сир, огірок, тунець, манго, світ чилі, унагі, тагараші, кумкват',
        price: '289',
        photo: '/img/sushi/5010_front.jpeg',
        category: '3'
      },
      {
        article: '5007',
        name: 'Золотий дракон',
        weight: '355',
        description: 'Рис, норі, вугор, огірок, авокадо, зелена цибуля, ікра тобіко, соус спайсі, лосось, унагі, томаго',
        price: '439',
        photo: '/img/sushi/5007_front.jpeg',
        category: '4'
      },
      {
        article: '5006',
        name: 'Тигровий дракон',
        weight: '375',
        description: 'Рис, норі, креветка, огірок, авокадо, зелена цибуля, соус спайсі, вугор, томаго, ікра тобіко',
        price: '415',
        photo: '/img/sushi/5006_front.jpeg',
        category: '4'
      },
      {
        article: '5013',
        name: 'Філадельфія з вугрем',
        weight: '320',
        description: 'Рис, норі, крем-сир, огірок, авокадо, вугор',
        price: '309',
        photo: '/img/sushi/5013_front.jpeg',
        category: '4'
      },
      {
        article: '5012',
        name: 'Смажений з креветкою',
        weight: '260',
        description: 'Рис, норі, крем-сир, креветка, моцарела, ікра тобіко, кляр панко, соус спайсі',
        price: '250',
        photo: '/img/sushi/5012_front.jpeg',
        category: '5'
      },
      {
        article: '5015',
        name: 'Філадельфія з креветкою',
        weight: '320',
        description: 'Рис, норі, крем-сир, огірок, авокадо, креветка',
        price: '335',
        photo: '/img/sushi/5015_front.jpeg',
        category: '5'
      },
      {
        article: '5004',
        name: 'Сет гунканів',
        weight: '570',
        description: 'Рис, норі, лосось, тунець, вугор, краб, ікра тобіко, соус спайсі, соус світ чилі',
        price: '730',
        photo: '/img/sushi/5004_front.jpeg',
        category: '6'
      },
      {
        article: '5001',
        name: 'Рол Снікерс',
        weight: '255',
        description: 'Крем-сир, Нутелла, банан, топінг полуниця, полуниця',
        price: '279',
        photo: '/img/sushi/5001_front.jpeg',
        category: '7'
      },
        {
          article: '5040',
          name: 'Напій Caycen SAGIKO 320 мл',
          weight: '',
          description: '',
          price: '120',
          photo: '/img/sushi/5040_front.png',
          category: '12'
        },
        {
          article: '5041',
          name: 'Напій Тамаринд SAGIKO 320 мл',
          weight: '',
          description: '',
          price: '120',
          photo: '/img/sushi/5041_front.png',
          category: '12'
        },
        {
          article: '5042',
          name: 'Напій Маракуйя SAGIKO 320 мл',
          weight: '',
          description: '',
          price: '120',
          photo: '/img/sushi/5042_front.png',
          category: '12'
        },
        {
          article: '5043',
          name: 'Напій Рожева Гуава SAGIKO 320 мл',
          weight: '',
          description: '',
          price: '120',
          photo: '/img/sushi/5043_front.png',
          category: '12'
        },
        {
          article: '5044',
          name: 'Напій Манго SAGIKO 320 мл',
          weight: '',
          description: '',
          price: '120',
          photo: '/img/sushi/5044_front.png',
          category: '12'
        },
        {
          article: '5045',
          name: 'Пепсі 0.33 скло',
          weight: '',
          description: '',
          price: '60',
          photo: '/img/sushi/5045_front.png',
          category: '12'
        },
        {
          article: '5046',
          name: 'Пепсі чорна 0.25 скло',
          weight: '',
          description: '',
          price: '60',
          photo: '/img/sushi/5046_front.png',
          category: '12'
        },
        {
          article: '5047',
          name: '7up 0.25 скло',
          weight: '',
          description: '',
          price: '50',
          photo: '/img/sushi/5047_front.png',
          category: '12'
        },
        {
          article: '5048',
          name: 'Mirinda 0.25 скло',
          weight: '',
          description: '',
          price: '50',
          photo: '/img/sushi/5048_front.png',
          category: '12'
        },
        {
          article: '5049',
          name: 'Карпатська Джерельна газ 0.5 скло',
          weight: '',
          description: '',
          price: '100',
          photo: '/img/sushi/5049_front.png',
          category: '12'
        },
        {
          article: '5050',
          name: 'Карпатська Джерельна н/газ 0.5 скло',
          weight: '',
          description: '',
          price: '100',
          photo: '/img/sushi/5050_front.png',
          category: '12'
        },
        {
          article: '5051',
          name: 'Сік Сандора апельсин 0.5',
          weight: '',
          description: '',
          price: '80',
          photo: '/img/sushi/5051_front.png',
          category: '12'
        },
        {
          article: '5052',
          name: 'Сік Сандора мультивітамін 0.5',
          weight: '',
          description: '',
          price: '80',
          photo: '/img/sushi/5052_front.png',
          category: '12'
        },
        {
          article: '5053',
          name: 'Сік Сандора томат 0.5',
          weight: '',
          description: '',
          price: '80',
          photo: '/img/sushi/5053_front.png',
          category: '12'
        },
        {
          article: '5054',
          name: 'Сік Сандора яблучний 0.5',
          weight: '',
          description: '',
          price: '80',
          photo: '/img/sushi/5054_front.png',
          category: '12'
        },
        {
          article: '5061',
          name: 'Креветка попкорн',
          weight: '162',
          description: 'Креветка, борошно, олія соняшникова, розпушувач, соус солодкий чилі, соус унагі',
          price: '170',
          photo: '/img/sushi/5061_front.jpeg',
          category: '8'
        },
        {
          article: '5062',
          name: 'Фіш боул',
          weight: '300',
          description: 'Лосось, цибуля синя, яйце куряче, панко, олія соняшникова, борошно, соус спайсі, соус унагі',
          price: '280',
          photo: '/img/sushi/5062_front.jpeg',
          category: '8'
        },
        {
          article: '5063',
          name: 'Тяхан з лососем',
          weight: '350',
          description: 'Рис, болгарський перець, морква, цибуля синя, цибуля зелена, огірок, кунжут, яйце куряче, соус солодкий чилі, соус унагі, соєвий соус, устричний соус, лосось',
          price: '280',
          photo: '/img/sushi/5063_front.jpeg',
          category: '8'
        },
        {
          article: '5064',
          name: 'Фунчоза з креветкою',
          weight: '330',
          description: 'Локшина фунчоза, цибуля синя, морква, перець болгарський, цибуля зелена, огірок, кунжут, соєвий соус, соус унагі, соус солодкий чилі, креветка',
          price: '330',
          photo: '/img/sushi/5064_front.jpeg',
          category: '8'
        },
        {
          article: '5065',
          name: 'Локшина соба з лососем',
          weight: '330',
          description: 'Локшина соба, перець болгарський, цибуля синя, морква, цибуля зелена, огірок, соус унагі, соєвий соус, соус солодкий чилі, лосось',
          price: '280',
          photo: '/img/sushi/5065_front.jpeg',
          category: '8'
        },

        {
          article: '5066',
          name: 'Футомакі з лососем',
          weight: '240',
          description: 'Рис, норі, крем-сир, огірок, лосось, соус спайсі',
          price: '215',
          photo: '/img/sushi/5066_front.jpeg',
          category: '1'
        },

        {
          article: '5067',
          name: 'Тяхан з креветкою',
          weight: '350',
          description: 'Рис, перець болгарський, морква, цибуля синя, цибуля зелена, огірок, кунжут, яйце куряче, соус солодкий чилі, соус унагі, соєвий соус, устричний соус, креветка',
          price: '270',
          photo: '/img/sushi/5067_front.jpeg',
          category: '8'
        },
        {
          article: '5068',
          name: 'Фунчоза з лососем',
          weight: '330',
          description: 'Локшина фунчоза, цибуля синя, морква, перець болгарський, цибуля зелена, огірок, кунжут, соєвий соус, соус унагі, соус солодкий чилі, лосось',
          price: '330',
          photo: '/img/sushi/5068_front.jpeg',
          category: '8'
        },
        {
          article: '5069',
          name: 'Локшина соба з креветкою',
          weight: '330',
          description: 'Локшина соба, перець болгарський, цибуля синя, морква, цибуля зелена, огірок, соус унагі, соєвий соус, соус солодкий чилі, креветка',
          price: '280',
          photo: '/img/sushi/5069_front.jpeg',
          category: '8'
        },
        {
          article: '5060',
          name: 'Вогняний дракон',
          weight: '370',
          description: 'Рис, норі, манго, огірок, крем-сир, лосось, унагі, вугор, тобіко, перець чилі',
          price: '420',
          photo: '/img/sushi/5060_front.jpeg',
          category: '1'
        },
        {
          article: '5070',
          name: 'Сет Токаш',
          weight: '1250',
          description: 'Рол з тунцем, спрінг рол з лососем, макі рол з огірком, каліфорнія з вугрем, тобіко, нігірі, футомакі з лососем, імбир, васабі',
          price: '1159',
          photo: '/img/sushi/5070_front.jpeg',
          category: '13'
        },
        {
          article: '5071',
          name: 'Сет Філадельфія мікс',
          weight: '720',
          description: 'Рол філадельфія мікс: лосось та тунець + Рол філадельфія мікс: креветка та вугор + Рол філадельфія з авокадо',
          price: '780',
          photo: '/img/sushi/5071_front.jpeg',
          category: '13'
        },
        {
          article: '5072',
          name: 'Рол Токаш',
          weight: '480',
          description: 'Рис, Норі, Крем-сир, Креветка, Яйце куряче, Панко, Огірок, Краб, Лосось, Ікра Тобіко, Кумкват, Устричний соус',
          price: '420',
          photo: '/img/sushi/5072_front.jpeg',
          category: '1'
        },
        {
          article: '5073',
          name: 'РолДог',
          weight: '460',
          description: 'Рис, норі, крем-сир, тостовий сир, огірок, креветка, крабова паличка, соус спайсі, соус унагі, панко, яйце куряче, борошно, олія соняшникова',
          price: '315',
          photo: '/img/sushi/5073_front.jpeg',
          category: '5'
        },
        {
          article: '5074',
          name: 'Рол від Шефа',
          weight: '430',
          description: 'Рис, норі, креветка, крем-сир, томаго, вугор, соус унагі, майонез, трюфельна сальса, стружка кокоса, огірок',
          price: '420',
          photo: '/img/sushi/5074_front.jpeg',
          category: '4'
        },
        {
          article: '5075',
          name: 'Рол Баунті',
          weight: '290',
          description: 'Тісто листове, крем-сир, кокосова стружка, пудра сахарна, банан, карамельний топінг',
          price: '239',
          photo: '/img/sushi/5075_front.jpeg',
          category: '7'
        },
        {
          article: '5078',
          name: 'Лимонад "Зандукелі" Груша 0.5 л',
          weight: '',
          description: '',
          price: '100',
          photo: '/img/sushi/5078_front.jpeg',
          category: '12'
        },
        {
          article: '5079',
          name: 'Лимонад "Зандукелі" Сапераві 0.5 л',
          weight: '',
          description: '',
          price: '100',
          photo: '/img/sushi/5079_front.jpeg',
          category: '12'
        },
        {
          article: '5080',
          name: 'Лимонад "Зандукелі" Тархун 0.5 л',
          weight: '',
          description: '',
          price: '100',
          photo: '/img/sushi/5080_front.jpeg',
          category: '12'
        },
        {
          article: '5081',
          name: 'Смажений с лососем',
          weight: '260',
          description: 'Рис, сир Чедар, лосось, крем сир, норі, соус Спайсі',
          price: '250',
          photo: '/img/sushi/5081_front.jpeg',
          category: '1'
        },
        {
          article: '5082',
          name: 'Рол без рису',
          weight: '220',
          description: 'Норі, лосось, тунець, креветка, авокадо, огірок',
          price: '489',
          photo: '/img/sushi/5082_front.jpeg',
          category: '3'
        },
        {
          article: '5083',
          name: 'Сет Класік',
          weight: '1400',
          description: 'Філадельфія з лососем, Футомакі з креветкою, Каліфорнія з вугрем, Ро чеддер, Макі з тунцем, Макі з авокадо і манго, Нігірі з лососем',
          price: '850',
          photo: '/img/sushi/5083_front.jpeg',
          category: '13'
        },
        {
          article: '5084',
          name: 'Темакі',
          weight: '250',
          description: 'Рис, норі, лосось, крем-сир, огірок, тобіко, унагі',
          price: '240',
          photo: '/img/sushi/5084_front.jpeg',
          category: '8'
        },
        {
          article: '5085',
          name: 'Суші Бургер',
          weight: '310',
          description: 'Рис, норі, сир чеддер, крем-сир, тобіко, лосось, креветки, краб, соус спайсі, соус унагі',
          price: '280',
          photo: '/img/sushi/5085_front.jpeg',
          category: '8'
        },
        {
          article: '5083',
          name: 'Сет Класік',
          weight: '1400',
          description: 'Філадельфія з лососем, Футомакі з креветкою, Каліфорнія з вугрем, Ро чеддер, Макі з тунцем, Макі з авокадо і манго, Нігірі з лососем',
          price: '850',
          photo: '/img/sushi/5083_front.jpeg',
          category: '15'
        },
        {
          article: '5084',
          name: 'Темакі',
          weight: '250',
          description: 'Рис, норі, лосось, крем-сир, огірок, тобіко, унагі',
          price: '240',
          photo: '/img/sushi/5084_front.jpeg',
          category: '15'
        },
        {
          article: '5085',
          name: 'Суші Бургер',
          weight: '310',
          description: 'Рис, норі, сир чеддер, крем-сир, тобіко, лосось, креветки, краб, соус спайсі, соус унагі',
          price: '280',
          photo: '/img/sushi/5085_front.jpeg',
          category: '15'
        },
        {
          article: '5555',
          name: 'Пакет',
          weight: '2',
          description: 'Пакет',
          price: '10',
          photo: '/img/sushi/5555_front.jpeg',
          category: '14'
        }
      ],
      sushi: null,
      quantity: 1,
      favoriteSushi: '',
      showLoginPopup: false, // Popup visibility state
      
    };
  },
   created() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
     const article = this.$route.params.article;
      try {
      this.sushi = this.sushiList.find(sushi => sushi.article === article);
      } catch (e) {
        console.error(`Sushi item with article ${article} not found.`);
      }
      if (!this.sushi) {
        console.error(`Sushi item with article ${article} not found.`);
      }
      
      this.fetchAndParseSushiData();
      this.loadFavoriteSushiFromStorage();
      
  },
  watch: {
    '$i18n.locale': 'fetchAndParseSushiData', // Watch for language changes
  },
  mounted() {
    // const article = this.$route.params.article;
    // this.sushi = sushiList.find(sushi => sushi.article === article);
  },
  methods: {
    async fetchUserInfo() {
      try {
        const token = this.getTokenWithExpiry('userToken'); // Ensure token exists

        if (!token) {
          this.redirectToSignIn();
          return;
        }

        const response = await axios.get('https://api.sushitokash.com.ua/api/users/info', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const userInfo = response.data;

        // Save the favoriteSushi string in data
        this.favoriteSushi = userInfo.favoriteSushi || '';
        this.saveFavoriteSushiToStorage(); // Store it in local storage

        console.log('User Info:', userInfo);
      } catch (error) {
        console.error('Error fetching user info:', error);
        this.redirectToSignIn(); // Handle error, like token expiration
      }
    },
    isFavorite(article) {
      const favoriteList = this.favoriteSushi.split(' ');
      return favoriteList.includes(article);
    },
    // Add sushi article to the favorite list
    async addToFavorite(article) {
      try {
        const token = this.getTokenWithExpiry('userToken'); // Ensure token exists
        console.log(token)
         if (!token) {
           console.log("Myu")
          this.showLoginPopup = true; // Show login popup if token is null
          return;
        }
        const favoriteList = this.favoriteSushi.split(' ');

        if (!favoriteList.includes(article)) {
          favoriteList.push(article);
          this.favoriteSushi = favoriteList.join(' ');

          // Make PUT request to update favorite sushi list on the server
          const response = await axios.put('https://api.sushitokash.com.ua/api/users/update-favorite-sushi', {
            favoriteSushiArticles: favoriteList
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

          // Update favoriteSushi with the response and save to localStorage
          this.favoriteSushi = response.data.favoriteSushi || '';
          this.saveFavoriteSushiToStorage(); // Save updated favorite sushi in local storage
        }
      } catch (error) {
        console.error('Error adding sushi to favorites:', error);
      }
    },


    // Remove sushi article from the favorite list
    async removeFromFavorite(article) {
      try {
        const token = this.getTokenWithExpiry('userToken'); // Ensure token exists
        console.log("REMOVE from f" )
        console.log(token);
        if (!token) {
          this.showLoginPopup = true; // Show login popup if token is null
          return;
        }
        let favoriteList = this.favoriteSushi.split(' ');

        if (favoriteList.includes(article)) {
          favoriteList = favoriteList.filter(fav => fav !== article);
          this.favoriteSushi = favoriteList.join(' ');

          const favoriteListToremove = article;


          // Make request to remove sushi article from favorite list on the server
          const response = await axios.put('https://api.sushitokash.com.ua/api/users/remove-favorite-sushi', {
            sushiArticlesToRemove: [favoriteListToremove]
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

          this.favoriteSushi = response.data.favoriteSushi || '';
          // Save the updated favorite list in localStorage
          this.saveFavoriteSushiToStorage();
        }
      } catch (error) {
        console.error('Error removing sushi from favorites:', error);
      }
    },
    loadFavoriteSushiFromStorage() {
      const storedFavorites = localStorage.getItem('favoriteSushi');
      if (storedFavorites) {
        this.favoriteSushi = storedFavorites; // Load as string
      }
    },
     // Save favorite sushi list to local storage
    saveFavoriteSushiToStorage() {
      localStorage.setItem('favoriteSushi', this.favoriteSushi); // Save as a space-separated string
    },
    getTokenWithExpiry(key) {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) {
        return null; // Token does not exist
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key); // Token expired
        return null;
      }
      return item.value; // Return valid token
    },
    parseSushiData(sushiData) {
      const isEnglish = this.$i18n.locale === 'en'; // Check the current language

      return sushiData.map(item => {
        let article = item.id ? item.id.toString() : '';

        // If the article starts with '7', remove the first character
        if (article.startsWith('7')) {
          article = article.substring(1);
        }

        return {
          article: article,
          name: isEnglish ? item.nameEn || '' : item.nameUa || '',
          description: isEnglish ? item.descriptionEn || '' : item.descriptionUa || '',
          weight: item.weight ? item.weight.toString() : '',
          price: item.price ? item.price.toString() : '',
          photo: item.imgMiddle || '',
          category: item.category || '',
        };
      });
    },
    fetchAndParseSushiData() {
      try {
      axios.get('https://api.sushitokash.com.ua/api/sushi-items')
        .then(response => {
          this.sushiList = this.parseSushiData(response.data);
          const article = this.$route.params.article;
          this.sushi = this.sushiList.find(sushi => sushi.article === article);
          //sushiList = this.sushiList;
          console.log(this.sushi);
          console.log(this.sushiList);
        })
        .catch(error => {
          console.error("There was an error fetching the sushi items:", error);
        });
      }  catch (error) {
                    console.error('Cannot update items:'); 
                }
    },
    filteredSushiList(article) {
    // Ensure categories is an array, even if a single category is passed
      if (!Array.isArray(article)) {
        article = [article];
      }
      return this.sushiList.filter(item => article.includes(item.article));
    },
     handleClick() {
      // Initiate a call
      window.location.href = 'tel:+380800338105';
      
      // Navigate to the sushi item
    },
    addToCart(item) {
      this.$emit('add-to-cart', item);
    },
    increaseQuantity() {
      this.quantity++;
    },
    decreaseQuantity() {
      if (this.quantity > 1) {
        this.quantity--;
      }
    },
    redirectToLogin() {
      this.$router.push("/login"); // Assuming you have a login route
    },
    navigateToSushiItem(article) {
      this.sushi = this.sushiList.find(sushi => sushi.article === article);
      this.$router.push({ name: 'SushiItem', params: { article } });
        // const articlee = this.$route.params.article;
      window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    }
  }
}
</script>

<style scoped>
.login-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #333;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
  min-width: 220px;
}

.login-popup h2 {
  margin-bottom: 20px;
}

.login-popup button {
  background-color: #ff5c5c;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.divs-ce {
  margin-top: 80px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.sushi-item{
  cursor: pointer;
}
.sushi-itemm-container {
  border-radius: 10px;
  width: 80%;
  max-width: 900px;
  padding: 20px;
  text-align: left;
}

.sushi-itemm {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.sushi-images {
  flex: 1;
  margin-right: 20px;
}

.main-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.thumbnail-images {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.thumbnail-images img {
  width: 23%;
  border-radius: 5px;
  cursor: pointer;
}

.sushi-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item-id {
  color: #FF5C5C;
  font-weight: bold;
  margin-bottom: 0px
}

h2 {
  margin: 10px 0;
  color: #E9E9E9;
}

.item-weight {
  color: #7bb249;
}

.item-description {
  margin: 10px 0;
}

.item-price {
  color: #FF5C5C;
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
}

.add-to-cart-container {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    /* background-color: #a4d95b; */
    padding: 10px;
    border-radius: 25px;
    width: 100%; /* Make the container take full width */
    max-width: 900px; /* Optional: Limit the max width */
}

.add-to-cart {
  display: flex;
  /* justify-content: space-between; */
  justify-content: flex-start; /* Align button and icon to the left */
  align-items: center;
  background-color: #a4d95b;
  padding: 10px;
  border-radius: 25px;
  font-size: 16px;
}

.cart-button-it {
    background-color: transparent;
    color: #333;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 0px 20px;
    border-radius: 25px;
    background-color: #a4d95b;
    margin-right: 10px; /* Space between the button and the heart icon */
}

.favorite-icon {
    margin-left: 10px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    /* margin-left: 15px; Adds space between button and icon */
}

.quantity-control {
  display: flex;
  align-items: center;
}

.quantity-control button {
  background-color: #ffffff;
  color: #FF5C5C;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}

.quantity-control button:hover {
  background-color: #e0e0e0;
}

.quantity-control .quantity {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
  .sushi-itemm {
    flex-direction: column;
    align-items: center;
  }

  .sushi-images {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .sushi-details {
    width: 100%;
  }
}

.sushi-item .article-number {
  display: block;
  margin-left: 10px;
  color: #FF5C5C;
  font-size: 0.9em;
}
</style>
