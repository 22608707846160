// i18n.js
import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import uk from './locales/uk.json';

const messages = {
  en, 
  uk
};

const i18n = createI18n({
  locale: 'uk', // Default locale
  fallbackLocale: 'en', 
  messages,
  warnHtmlMessage: false,
});

export default i18n;
