<template>
  <section class="offer-section">
    <section class="offer-section t2">
      <h2>{{ $t('offer.title') }}</h2>
      <p>
        {{ $t('offer.intro') }}
        <a href="https://sushitokash.com.ua/" target="_blank">https://sushitokash.com.ua/</a>
        ({{ $t('offer.seller') }}).
      </p>
    </section>

    <section class="offer-section t2">
      <h2>{{ $t('offer.general.title') }}</h2>
      <p>{{ $t('offer.general.content1') }}</p>
      <p>{{ $t('offer.general.content2') }}</p>
    </section>

    <section class="offer-section t2">
      <h2>{{ $t('offer.subject.title') }}</h2>
      <p>{{ $t('offer.subject.content1') }}</p>
      <p>{{ $t('offer.subject.content2') }}</p>
    </section>

    <section class="offer-section t2">
      <h2>{{ $t('offer.order.title') }}</h2>
      <p>{{ $t('offer.order.content1') }}</p>
      <p>{{ $t('offer.order.content2') }}</p>
    </section>

    <section class="offer-section t2">
      <h2>{{ $t('offer.other.title') }}</h2>
      <p>{{ $t('offer.other.content1') }}</p>
      <p>{{ $t('offer.other.content2') }}</p>
    </section>
  </section>
</template>


<script>
export default {
  name: "OfferAgreement",
  created() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  },
};
</script>

<style scoped>
.offer-section {
  text-align: left;
  padding: 20px;
  margin-top: 40px;
  padding-left: 10%;
  padding-right: 10%;
}

.t2 {
  margin-top: 10px !important;
  padding-top: 0px;
}

.offer-section h2 {
  color: #e9e9e9;
  margin-bottom: 20px;
}

.offer-section p {
  max-width: 600px;
  color: #e9e9e9;
  text-align: left;
}

.offer-section a {
  color: #88c057;
  text-decoration: none;
}

.offer-section a:hover {
  text-decoration: underline;
}
</style>
