<template>
  <div class="section-map-header">
      <h2>{{ $t('map.title') }}</h2>
    </div>
  <section class="section-map" v-for="(restaurant, index) in activeRestaurants" :key="restaurant.id || index">
    <div class="map-container">
      <iframe
        :src="restaurant.gmapsLink || defaultGmapsLink"
        allowfullscreen
      ></iframe>
    </div>

    <div class="contact-info">
      <div class="contact-item">
        <h3>{{ $t('map.addressTitle') }}</h3>
        <p>
          <a :href="restaurant.gmapsLink || defaultGmapsLink">{{ restaurant.address || defaultAddress }}</a>
        </p>
      </div>
      <div class="contact-item">
        <h3>{{ $t('map.workingHoursTitle') }}</h3>
        <p>
          {{ $t('map.workingHours') }} 
          {{ restaurant.workingHours?.restaurant_hours.start || defaultWorkingHoursStart }} - 
          {{ restaurant.workingHours?.restaurant_hours.end || defaultWorkingHoursEnd }}
        </p>
      </div>
      <div class="contact-item">
        <h3>{{ $t('map.orderHoursTitle') }}</h3>
        <p>
          {{ $t('map.orderHours') }} 
          {{ restaurant.workingHours?.delivery_time.start || defaultOrderHoursStart }} - 
          {{ restaurant.workingHours?.delivery_time.end || defaultOrderHoursEnd }}
        </p>
      </div>
      <div class="contact-item">
        <h3>{{ $t('map.contactTitle') }}</h3>
        <p>
          <a href="tel:+380800338105">{{ $t('map.contactNumber') }}</a>
        </p>
      </div>
    </div>
  </section>
</template>



<script>
export default {
  name: "Contact-us",
  data() {
    return {
      restaurantDetails: [], // Populate this with actual data
      // Default values for map and contact details
      defaultGmapsLink: "https://www.google.com/maps/d/embed?mid=17IbdZ8qoGTUilV9D5Qa2Bbed5buFFCs&ehbc=2E312F",
      defaultAddress: "Вул. Осокорська 2 А Кафе «Шиномонтаж» 5 підʼїзд",
      defaultWorkingHoursStart: "10:00",
      defaultWorkingHoursEnd: "22:00",
      defaultOrderHoursStart: "11:00",
      defaultOrderHoursEnd: "21:30",
    };
  },
  computed: {
    activeRestaurants() {
      return this.restaurantDetails.filter(restaurant => restaurant.status === "ACTIVE");
    }
  },
  created() {
    // Load restaurant details from localStorage or other sources
    const storedDetails = localStorage.getItem('restaurantDetails');
    if (storedDetails) {
      this.restaurantDetails = JSON.parse(storedDetails);
    }
  }
};
</script>


<style scoped>
a {
  color: #E9E9E9;
}

.section-map {
  width: 100%;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.section-map-header {
  text-align: center;
  padding: 40px 20px;
}

.section-map-header h2 {
  font-size: 32px;
  margin-bottom: 10px;
  color: #E9E9E9;
}

.section-map-header p {
  font-size: 18px;
  color: #555;
}

.map-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-left: 80px;
  margin-right: 80px;
  overflow: hidden;
}

.map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.contact-info {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 20px;
}

.contact-item {
  flex: 1 1 300px;
  margin: 20px;
  text-align: center;
}

.contact-item h3 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #E9E9E9;
}

.contact-item p {
  font-size: 16px;
  color: #E9E9E9;
}

@media (max-width: 768px) {
  .section-map-header h2 {
    font-size: 24px;
  }

  .section-map-header p {
    font-size: 16px;
  }

  .contact-item h3 {
    font-size: 20px;
  }

  .contact-item p {
    font-size: 14px;
  }
  .map-container {
    position: relative;
    padding-bottom: 70.25%;
    height: 0;
    margin-left: 50px;
    margin-right: 50px;
    overflow: hidden;
  }
}

@media (max-width: 450px) {
  .map-container {
    position: relative;
    padding-bottom: 100.25%;
    height: 0;
    margin-left: 10px;
    margin-right: 10px;
    overflow: hidden;
  }
}
</style>
