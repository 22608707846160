<template>
  <div :class="['sushi-types-container', { visible: menuVisible }]">
    <div class="sushi-types">
      <a href="#/#section-losos">
        <img src="../assets/icons/Salmon.svg" alt="Sushi Icon">
        <span>{{ $t('account-page.sushi-types.salmon') }}</span>
      </a>
      <a href="#/#section-krab">
        <img src="../assets/icons/crab.svg" alt="Sushi Icon">
        <span>{{ $t('account-page.sushi-types.crab') }}</span>
      </a>
      <a href="#/#section-tunets">
        <img src="../assets/icons/tuna.svg" alt="Sushi Icon">
        <span>{{ $t('account-page.sushi-types.tuna') }}</span>
      </a>
      <a href="#/#section-vugor">
        <img src="../assets/icons/acne.svg" alt="Sushi Icon">
        <span>{{ $t('account-page.sushi-types.eel') }}</span>
      </a>
      <a href="#/#section-krevetka">
        <img src="../assets/icons/shrimp.svg" alt="Sushi Icon">
        <span>{{ $t('account-page.sushi-types.shrimp') }}</span>
      </a>
      <a href="#/#section-gunkani">
        <img src="../assets/icons/set.svg" alt="Sushi Icon">
        <span>{{ $t('account-page.sushi-types.sets') }}</span>
      </a>
      <a href="#/#section-dishes">
        <img src="../assets/icons/dishes.svg" alt="Sushi Icon">
        <span>{{ $t('account-page.sushi-types.dishes') }}</span>
      </a>
      <a href="#/#section-drinks">
        <img src="../assets/icons/drink.svg" alt="Sushi Icon">
        <span>{{ $t('account-page.sushi-types.drinks') }}</span>
      </a>
    </div>
  </div>
  <div class="address-form">
    <div class="profile-header">
      <div class="profile-info">
        <div class="profile-image">🍣</div>
        <div class="profile-details">
          <p class="name">{{ profileName }}</p>
          <p class="email">{{ profileEmail }}</p>
          <p class="phone">{{ profilePhone }}</p>
        </div>
      </div>
      <button @click="logout" class="logout-btn">{{ $t('account-page.profile.logout') }}</button>
    </div>

    <div class="navigation-n">
      <button @click="navigate('orders')" :class="{ active: currentSection === 'orders' }" class="nav-btn">
        {{ $t('account-page.profile.orders') }}
      </button>
      <button @click="navigate('addresses')" :class="{ active: currentSection === 'addresses' }" class="nav-btn">
        {{ $t('account-page.profile.addresses') }}
      </button>
      <button @click="navigate('favorites')" :class="{ active: currentSection === 'favorites' }" class="nav-btn">
        {{ $t('account-page.profile.favorites') }}
      </button>
    </div>

    <!-- Conditional rendering based on currentSection value -->
    <div v-if="currentSection === 'addresses'" class="address-section">
      <h2>{{ $t('account-page.profile.addresses') }}</h2>
      <form @submit.prevent="submitAddress" class="address-from">
        <div class="form-group">
          <label for="street">{{ $t('account-page.address-form.street') }}</label>
          <input type="text" id="street" maxlength="40" v-model="address.street" @input="onAddressInput"/>
        </div>
        <div class="form-group">
          <label for="house">{{ $t('account-page.address-form.house') }}</label>
          <input type="text" id="house" maxlength="10" v-model="address.house" @input="onAddressInput"/>
        </div>
        <div class="form-group-row">
          <div class="form-group">
            <label for="building">{{ $t('account-page.address-form.building') }}</label>
            <input type="text" id="building" v-model="address.building" @input="onAddressInput"/>
          </div>
          <div class="form-group">
            <label for="floor">{{ $t('account-page.address-form.floor') }}</label>
            <input type="text" id="floor" v-model="address.floor" @input="onAddressInput"/>
          </div>
          <div class="form-group">
            <label for="apartment">{{ $t('account-page.address-form.apartment') }}</label>
            <input type="text" id="apartment" v-model="address.apartment" @input="onAddressInput"/>
          </div>
        </div>
        <button type="submit" class="submit-btn" v-show="isAddressModified">{{ $t('account-page.address-form.save') }}</button>
      </form>
    </div>


    <div v-if="currentSection === 'orders'" class="orders-section">
      <h2>{{ $t('account-page.profile.orders') }}</h2>
      <div v-if="userOrders.length > 0" class="orders-list">
        <div v-for="(order) in userOrders" :key="order.id" class="order">
          <div class="order-header">
            <h3>{{ $t('account-page.orders.order') }} #{{ order.id }}</h3>
            <p>{{ formatDate(order.created) }}</p>
          </div>
          <div class="order-items">
            <div class="order-item" v-for="item in parseItems(order.items)" :key="item.article">
              <img :src="'/img/sushi/' + item.article + '_front_mini.jpeg'" :alt="item.name" class="item-image">
              <div class="item-info">
                <h4>{{ item.name }}</h4>
                <p>{{ $t('account-page.orders.price') }}: {{ item.price }} грн</p>
                <p>{{ $t('account-page.orders.quantity') }}: {{ item.quantity }}</p>
              </div>
            </div>
          </div>
          <div class="order-footer">
            <p>{{ $t('account-page.orders.total-weight') }} {{ order.weight }} грам</p>
            <p>{{ $t('account-page.orders.total-price') }} {{ order.price }} грн</p>
          </div>
          <button @click="repeatOrder(order)" class="repeat-order-btn">
            {{ $t('account-page.orders.repeat-order') }}
            <span class="icon-wrapper">></span>
          </button>
        </div>
      </div>
      <div v-else>
        <p>{{ $t('account-page.orders.no-orders') }}</p>
      </div>
    </div>

    <div v-if="currentSection === 'favorites'" class="favorites-section">
      <h2>{{ $t('account-page.profile.favorites') }}</h2>
      <p v-if="!filteredFavoriteSushi.length">{{ $t('account-page.favorites.no-favorites') }}</p>
      <section class="sushi-section" id="section-favorites" v-else>
        <div class="sushi-grid">
          <div
            class="sushi-item"
            v-for="item in filteredFavoriteSushi"
            :key="item.article"
            @click="navigateToSushiItem(item.article)"
          >
            <img :src="item.photo" :alt="item.name">
            <h3 class="m-b">{{ item.name }}</h3>
            <span class="article-number">{{ item.article }}</span>
            <p class="g-weight" v-if="item.weight">{{ item.weight }} грам</p>
            <p class="m-t">{{ item.description }}</p>
            <div class="button-container">
              <span class="price">{{ item.price }} грн</span>
              <button @click.stop="addToCart(item)">
                {{ $t('account-page.favorites.want') }}
                <img src="../assets/plus_icon.png" alt="Plus Icon">
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CryptoJS from 'crypto-js';


export default {
  data() {
    return {
      menuVisible: true,
      sushiList: [],
      profileImage: '', // Add your profile image URL here or leave blank for no image
      profileName: '',
      profileEmail: '',
      profilePhone: '',
      currentSection: 'addresses', // Default section to display
      address: {
        street: '',
        house: '',
        building: '',
        floor: '',
        apartment: '',
      },
      isAddressModified: false, 
      favoriteSushi: '', // Save it as a string
      userOrders: []
    };
  },
  created() {
    this.checkToken(); // Check token on component mount
  },
  

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    this.fetchAndParseSushiData();
  },
  computed: {
    filteredFavoriteSushi() {
        const favoriteArticles = this.favoriteSushi.split(' ').filter(Boolean); // Split and filter non-empty
        return this.sushiList.filter(item => favoriteArticles.some(favArticle => favArticle.trim() === item.article.trim()));
    },


    isAddressFormFilled() {
      if (!this.address) return false; // Check if address is null or undefined

      return (
        this.address.street?.trim() !== '' ||
        this.address.building?.trim() !== '' ||
        this.address.house?.trim() !== '' ||
        this.address.floor?.trim() !== '' ||
        this.address.apartment?.trim() !== ''
      );
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    saveUserProfile() {
      const userProfile = {
        name: this.profileName,
        phone: this.profilePhone,
        email: this.profileEmail,
        address: {
          street: this.address.street,
          house: this.address.house,
          building: this.address.building,
          floor: this.address.floor,
          apartment: this.address.apartment,
        }
      };

      const userProfileString = JSON.stringify(userProfile);

      // Encrypt the data using AES
      const encryptedData = CryptoJS.AES.encrypt(userProfileString, 'sushitokash').toString();

      // Store encrypted data in localStorage
      localStorage.setItem('userProfile', encryptedData);
    },


    repeatOrder(order) {
      try {
        // Parse the 'items' string into an array
        const items = JSON.parse(order.items);
        
        // Loop through each item and add it to the cart based on its quantity
        items.forEach(item => {
          const cartItem = {
            article: item.article,
            name: item.name,
            price: item.price,
            photo: `/img/sushi/${item.article}_front_mini.jpeg`,
            quantity: item.quantity
          };
          for (let i = 0; i < cartItem.quantity; i++) {
            this.addToCart(cartItem); // Add the item to the cart
          }
        });

        console.log('Order repeated successfully:', order);
      } catch (error) {
        console.error('Error parsing order items:', error);
      }
    },



    parseItems(itemsString) {
      try {
        return JSON.parse(itemsString); // Parse the JSON string of items
      } catch (error) {
        console.error('Failed to parse items:', error);
        return []; // Return an empty array in case of error
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('uk-UA', options);
    },
    
     onAddressInput() {
      this.isAddressModified = true; // Mark address as modified when user inputs anything
    },
    addToCart(item) {
      this.$emit('add-to-cart', item);
    },
    navigateToSushiItem(article) {
      this.$router.push({ name: 'SushiItem', params: { article } });
    },
    checkToken() {
      const hashParams = window.location.hash.split('?')[1];
      const urlParams = new URLSearchParams(hashParams);
      const token = urlParams.get('tk');
      
      
      if (token) {
        const expiryTime = 23 * 24 * 60 * 60 * 1000; // 23 days in milliseconds
        this.setTokenWithExpiry('userToken', token, expiryTime);

        // Remove the 'tk' parameter from the URL without reloading the page
        urlParams.delete('tk');

        // Update the URL with the remaining parameters (if any)
        const newHash = `${window.location.hash.split('?')[0]}${urlParams.toString() ? '?' + urlParams.toString() : ''}`;

        // Use history.replaceState to update the URL
        history.replaceState(null, '', newHash);
      }
      
      const storedToken = this.getTokenWithExpiry('userToken');
      if (!storedToken) {
        this.redirectToSignIn(); // Redirect to sign-in if token is missing or expired
      }
      this.fetchUserInfo();
    },
    getTokenWithExpiry(key) {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) {
        return null; // Token does not exist
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key); // Token expired
        return null;
      }
      return item.value; // Return valid token
    },
    setTokenWithExpiry(key, value, ttl) {
      const now = new Date();
      const item = {
        value: value,
        expiry: now.getTime() + ttl, // TTL is in milliseconds
      };
      localStorage.setItem(key, JSON.stringify(item));
    },
    redirectToSignIn() {
      this.$router.push('/registration'); // Redirect to sign-in page
    },
    submitAddress() {
      // Handle form submission, e.g., send the address to the backend
      this.updateAddress();
      console.log("Address saved:", this.address);
    },
    logout() {
      // Handle logout logic
      localStorage.removeItem("userToken");
      localStorage.removeItem("favoriteSushi");
      localStorage.removeItem("userProfile");
      console.log("Logging out...");
      window.location.href = '#/'; 
    },
    navigate(section) {
      // Set the current section based on the clicked button
      this.currentSection = section;
    },
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop < 100) {
        // Less than 100px from the top, show the menu
        this.menuVisible = true;
      } else if (scrollTop > this.lastScrollTop) {
        // Scrolling down, hide the menu
        this.menuVisible = false;
      } else {
        // Scrolling up, show the menu
        this.menuVisible = true;
      }

      this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
    },


    fetchAndParseSushiData() {
      try {
      axios.get('https://api.sushitokash.com.ua/api/sushi-items')
        .then(response => {
          this.sushiList = this.parseSushiData(response.data);
          console.log(this.sushiList);
        })
        .catch(error => {
          console.error("There was an error fetching the sushi items:", error);
        });
      } catch (error) {
                    console.error('Cannot update items:'); 
                }
    },
    filteredSushiList(categories) {
    // Ensure categories is an array, even if a single category is passed
      if (!Array.isArray(categories)) {
        categories = [categories];
      }
      return this.sushiList.filter(item => categories.includes(item.category));
    },

    parseSushiData(sushiData) {
      return sushiData.map(item => {

        // Convert the item.id to a string and check if it starts with '7'
          let article = item.id ? item.id.toString() : '';
          
          // If the article starts with '7', remove the first character
          // if (article.startsWith('7')) {
          //     article = article.substring(1); // Remove the first character
          // }
        return {
          article: article,
          name: item.nameUa || '', // Fallback to empty string if nameUa is null
          weight: item.weight ? item.weight.toString() : '', // Handle null or undefined weight
          description: item.descriptionUa || '', // Fallback to empty string if descriptionUa is null
          price: item.price ? item.price.toString() : '', // Handle null or undefined price
          photo: item.imgMiddle || '', // Fallback to empty string if imgMiddle is null
          category: item.category || '' // Fallback to empty string if category is null
        };
      });
    },
    async fetchUserInfo() {
      try {
        const token = this.getTokenWithExpiry('userToken'); // Ensure token exists

        if (!token) {
          this.redirectToSignIn();
          return;
        }

        const response = await axios.get('https://api.sushitokash.com.ua/api/users/info-wto', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const userInfo = response.data.user;
        // Fill user data in the UI
        this.profileName = userInfo.name;
        this.profilePhone = userInfo.number;
        this.profileEmail = userInfo.email;
        this.address.street = userInfo.street;
        this.address.house = userInfo.buildingNumber;
        this.address.building = userInfo.entrance;
        this.address.floor = userInfo.floor;
        this.address.apartment = userInfo.apartment;

        // Save the entire favoriteSushi string
        this.favoriteSushi = userInfo.favoriteSushi || '';
        this.saveFavoriteSushiToStorage(); // Store it in local storage

        this.saveUserProfile();
        this.userOrders = response.data.orders;

      } catch (error) {
        console.error('Error fetching user info:', error);
        this.redirectToSignIn(); // Handle error, like token expiration
      }
    },
    saveFavoriteSushiToStorage() {
      localStorage.setItem('favoriteSushi', this.favoriteSushi); // Save as it is
    },

     updateAddress() {
      const addressData = {
        street: this.address.street,
        buildingNumber: this.address.house,
        entrance: this.address.building,
        floor: this.address.floor,
        apartment: this.address.apartment,
      };
      
      const token = this.getTokenWithExpiry('userToken'); // Ensure token exists

        if (!token) {
          this.redirectToSignIn();
          return;
        }

      axios.put('https://api.sushitokash.com.ua/api/users/update-address', addressData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => {
          console.log('Address updated successfully:', response.data);
           this.isAddressModified = false; // R
    
        })
        .catch(error => {
          console.error('Error updating address:', error);
          // Handle error response here
        });
    }

  },
};
</script>

<style scoped>
/* Base styles */
.address-form {
  margin-top: 100px !important; 
  color: #ffffff;
  
  padding: 20px;
  border-radius: 10px;
  max-width: 1200px; /* Max width for large screens */
  margin: 0 auto; /* Centering the form on the page */
  width: 85%;
}

/* Profile Header Section */
.profile-header {
  display: flex;
  flex-direction: column; /* Change to column to stack profile info and button */
  align-items: center;
  margin-bottom: 30px;
  border-radius: 5px;
  padding:  15px;
  background: #3b3a3a;
  margin-left: auto;
  margin-right: auto;
}

.profile-info {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align profile info to the left */
  width: 100%;
  margin-bottom: 15px; /* Add margin between profile info and the logout button */
}

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 20%;
  margin-right: 15px;
  background-color: #cccccc;
  object-fit: cover; /* Ensures the image covers the entire container */
  display: block;
  background-size: cover; /* Stretches the background image */
}


.item-image {
  width: 100px;
  height: auto;
  border-radius: 5px;
  margin-right: 15px;
  object-fit: cover; /* Ensures the image covers the area without distortion */
}

.profile-details{
  text-align: left;
}
.profile-details p {
  margin: 0;
}

.phone {
  color: #ffffff;
}
.name {
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
}

.logout-btn {
  background-color: #a7ff62;
  border: none;
  padding: 10px 20px;
  color: black;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 100%; /* Stretch the button to fit the width of the container */
  text-align: center; /* Center text inside the button */
}

/* Navigation Buttons */
.navigation-n {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  background: #3b3a3a;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}

.nav-btn {
  background-color: transparent;
  color: #A6A6A6;
;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin: 0 10px;
  font-size: 16px;
  width: 99%;
}

.nav-btn.active {
  background-color: #676767;
  border-radius: 5px;
  margin: 2px;
  color: #ffffff;
}

/* Address Section Layout */
.orders-section,
.favorites-section {
  margin-top: 30px;
  padding: 20px;
  /* background-color: #252525; */
  border-radius: 10px;
}

.address-section {
  margin-top: 30px;
  
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.address-from {
  background: #3b3a3a;
  padding: 15px;
  border-radius: 10px;
}

.address-section input {
  width: 90%;
}
h2 {
  color: #fff;
  margin-bottom: 20px;
  text-align: left;
  font-size: 22px;
}

/* Form Fields and Layout */
.form-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.form-group-row {
  display: flex;
  justify-content: space-between;
}
.form-group-row .form-group {
  width: 30%;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #fff;
  font-size: 16px;
  text-align: left;
}

input {
  width: 100%;
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #333;
  background-color: #5d5d5d;
  color: #fff;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

input:focus {
  background-color: #3a3a3a;
}

/* Submit Button */
.submit-btn {
  display: block;
  margin: 15px auto;
  background-color: #a7ff62;
  border: none;
  padding: 12px 40px;
  color: black;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #95e055;
}

/* Orders and Favorites Sections */
.orders-section,
.favorites-section {
  text-align: center;
}

p {
  font-size: 16px;
  color: #aaa;
}

/* Responsive Layout */
@media (max-width: 768px) {
  .address-section {
    max-width: 400px; /* Smaller screen adaptation */
    margin: 0 auto;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .profile-header,
  .navigation-n {
    flex-direction: column; /* Stacks profile and navigation-n for small screens */
    text-align: center;
  }

  .profile-header .logout-btn {
    margin-top: 15px;
  }

  .nav-btn {
    margin-bottom: 10px; /* Stack buttons for small screens */
  }
  .address-section input {
    width: 80%;
  }
}

@media (min-width: 769px) {
  .address-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 800px;
    margin: 0 auto;
  }

  .form-group {
    width: 48%; /* Make input fields appear side by side for larger screens */
    margin-bottom: 20px;
  }

  .form-group:last-child {
    /* margin-bottom: 0; */
  }

  /* Flexbox grid layout for the form */
  .form-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .profile-header,
  .navigation-n {
    max-width: 800px;
    /* margin: 0 auto; */
  }

  .navigation-n {
    justify-content: space-between;
  }
}





.sushi-types-container {
  width: 100%;
  /* background-color: #333; */
  padding: 5px 0px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  position: fixed;
  top: 0;
  z-index: 2;
  transition: transform 0.3s ease;
  transform: translateY(-100%); /* Hide the menu by default */
}

.sushi-types-container.visible {
  transform: translateY(0); /* Show the menu when visible */
}

.sushi-types {
  display: flex;
  justify-content: space-between;
  width: 60%;  /* Adjust this value as needed */
  margin: 0 auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}
.sushi-types::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers */
}

.sushi-types a {
  text-decoration: none;
  color: inherit;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.sushi-types a img {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
}

.sushi-types a span {
  margin-top: 5px;
  font-size: 14px;
  color: #E9E9E9;
}

@media (max-width: 700px) {
  .sushi-types {
    width: 86%;
    overflow-x: auto;
    gap: 10px;
  }
  .sushi-types::-webkit-scrollbar {
    display: none; /* Hide scrollbar in WebKit browsers */
  }
}

@media (max-width: 500px) {
  .sushi-types {
    width: 100%;
    overflow-x: auto;
    padding: 5px 15px;
  }
  .sushi-types::-webkit-scrollbar {
    display: none; /* Hide scrollbar in WebKit browsers */
  }

  .sushi-types a img {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .sushi-types a span {
    font-size: 13px;
  }
}

@media (max-width: 380px) {
  .sushi-types {
    width: 100%;
    overflow-x: auto;
    padding: 5px 3px;
    gap: 7px;
  }
  .sushi-types::-webkit-scrollbar {
    display: none; /* Hide scrollbar in WebKit browsers */
  }

  .sushi-types a img {
    width: 27px;
    height: 27px;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .sushi-types a span {
    font-size: 12px;
  }
}

@media (max-width: 330px) {
  .sushi-types {
    width: 100%;
    overflow-x: auto;
    padding: 5px 0px;
  }
  .sushi-types::-webkit-scrollbar {
    display: none; /* Hide scrollbar in WebKit browsers */
  }

  .sushi-types a img {
    width: 27px;
    height: 27px;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .sushi-types a span {
    font-size: 12px;
  }
}




/* Additional styles for the sushi sections and items */
.sushi-section {
  margin: 20px auto;
  padding: 20px;
  width: 80%;
  padding-top: 60px; /* Adjust to avoid overlapping with the fixed header */
}

.sushi-section h2 {
  color: #E9E9E9;
  margin-bottom: 20px;
  text-align: left;
}

.sushi-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.sushi-item {
  cursor: pointer;
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sushi-item .article-number {
  display: block;
  margin-left: 10px;
  color: #FF5C5C;
  font-size: 0.9em;
}

.sushi-item img {
  width: 100%;
  height: auto;
}

.sushi-item p {
  padding: 10px;
  color: #E9E9E9;
}

.sushi-item h3 {
  padding: 10px;
  color: #E9E9E9;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.price {
  font-size: 18px;
  color: #FF5C5C;
  font-weight: bold;
}

.sushi-item button {
  background-color: #88c057;  /* Light green background color */
  color: #333;  /* Darker text color for contrast */
  border: none;
  border-radius: 20px;
  padding: 10px;
  margin-left: 10px;
  cursor: pointer;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: relative;
}

.sushi-item button:hover {
  background-color: #7bb249;  /* Slightly darker green on hover */
}

.sushi-item button img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.sushi-item .weight {
  color: #FF5C5C;
  margin-bottom: 5px;
  font-size: 1em;
  font-weight: bold;
}




.orders-section {
  margin-top: 30px;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.order {
  background-color: #333;
  margin-top: 30px;
  padding: 20px;
  border-radius: 10px;
}

.order-history {
  background-color: #444;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 15px;
  text-align: left;
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.order-header h3 {
  color: #fff;
}

.order-date {
  color: #ff5c5c;
}

.order-items {
  display: flex;
  flex-wrap: wrap; /* Ensure items wrap when screen is smaller */
  gap: 15px;
  margin-bottom: 15px;
}

.order-item {
  background-color: #555;
  border-radius: 10px;
  padding: 10px;
  flex: 1 1 200px; /* Allow items to shrink/grow based on screen size */
  display: flex;
  align-items: center;
}

.item-image {
  width: 100px;
  height: auto;
  border-radius: 5px;
  margin-right: 15px;
}

.item-info h4 {
  color: #fff;
  margin-bottom: 5px;
}

.item-info p {
  color: #aaa;
}

.repeat-order-btn {
  background-color: #a7ff62;
  color: #333;
  border: none;
  padding: 2px 20px; /* Adjusted padding for smaller size */
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px; /* Slightly smaller font */
  display: inline-flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.repeat-order-btn:hover {
  background-color: #95e055;
}

.icon-wrapper  {
  background-color: #fff;
  border-radius: 50%;
  color: red;
  padding: 7px; /* Adjusted padding */
  margin-left: 10px; /* Reduced space */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 18px; /* Smaller size */
  height: 18px;
}

/* Add media queries for responsiveness */

@media (max-width: 1024px) {
  .order-items {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .order-items {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }

  .order-item {
    /* width: 100%; Ensure items take full width on small screens */
  }
}

@media (max-width: 480px) {
  .order-header, .order-footer {
    text-align: center;
  }

  .order-item {
    flex-direction: column; /* Stack image and info vertically */
    align-items: center;
  }

  .item-image {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
</style>
