<template>
  <router-view @add-to-cart="emitAddToCart"></router-view>
</template>

<script>
export default {
  methods: {
    // emitAddToCart(item) {
    //   this.$emit('add-to-cart', item);
    // }
  }
}
</script>
