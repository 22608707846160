<template>
  <div :class="{'blur-background-regform': showPopup}" class="registration-form-regform">
    <div v-if="!showVerificationForm" class="registration-form-regform-f">
      <h2>{{ $t('altregistration.registerTitle') }}</h2>
      <form @submit.prevent="registerUser" class="registration-fields-regform">
        <div class="form-group-regform">
          <label for="name">{{ $t('altregistration.nameLabel') }}<span class="required-regform">*</span></label>
          <input
            type="text"
            id="name"
            v-model="user.name"
            maxlength="40"
            :class="{ 'input-error': !nameValid }"
            required
            :placeholder=" $t('altregistration.nameLabel')"
          />
        </div>
        <div class="form-group-regform">
          <label for="phone">{{ $t('altregistration.phoneLabel') }}<span class="required-regform">*</span></label>
          <input
            type="tel"
            id="phone"
            v-model="user.phone"
            :class="{ 'input-error': !phoneValid }"
            maxlength="13"
            required
            placeholder="+380 540 555 555"
            @input="formatPhoneNumber"
          />
        </div>
        <div class="form-group-regform">
          <label for="email">{{ $t('altregistration.emailLabel') }}<span class="required-regform">*</span></label>
          <input
            type="email"
            id="email"
            v-model="user.email"
            maxlength="38"
            :class="{ 'input-error': !emailValid }"
            required
            placeholder="example@mail.com"
          />
        </div>

        <!-- Password Input -->
        <div class="form-group-regform form-group-passwords">
          <label for="password">{{ $t('altregistration.passwordLabel') }}<span class="required-regform">*</span></label>
          <input
            type="password"
            id="password"
            v-model="user.password"
            :class="{ 'input-error': !passwordValid }"
            required
            :placeholder="$t('altregistration.passwordPlaceholder')"
          />
          <p v-if="!passwordValid" class="error-message">{{ $t('altregistration.passwordError') }}</p>
        </div>

        <!-- Confirm Password Input -->
        <div class="form-group-regform form-group-passwords">
          <label for="confirmPassword">{{ $t('altregistration.confirmPasswordLabel') }}<span class="required-regform">*</span></label>
          <input
            type="password"
            id="confirmPassword"
            v-model="user.confirmPassword"
            :class="{ 'input-error': !passwordsMatch }"
            required
            :placeholder="$t('altregistration.confirmPasswordLabel') "
          />
          <p v-if="!passwordsMatch" class="error-message">{{ $t('altregistration.passwordMismatchError') }}</p>
        </div>
      </form>
    </div>

    <!-- Registration Button outside the form -->
    <div v-if="!showVerificationForm" class="button-container-regform">
      <div class="account-text">
        <span>{{ $t('altregistration.accountExists') }}</span>
        <a href="#/login">{{ $t('altregistration.loginLink') }}</a>
      </div>
      <button @click="registerUser" class="submit-btn-regform" :disabled="!isFormValid || isSubmitting">
        {{ $t('altregistration.registerButton') }}
        <span class="icon-wrapper-regform">
          >
        </span>
      </button>
    </div>

    <!-- Verification Code Form -->
    <div v-if="showVerificationForm" class="registration-form-regform-f">
      <h2>{{ $t('altregistration.verificationTitle') }}</h2>
      <p>{{ $t('altregistration.verificationInstruction') }}</p>
      <form @submit.prevent="verifyCode" class="registration-fields-regform">
        <div class="form-group-regform">
          <label for="verificationCode">{{ $t('altregistration.verificationCodeLabel') }}<span class="required-regform">*</span></label>
          <input
            type="text"
            id="verificationCode"
            v-model="verificationCode"
            :class="{ 'input-error': verificationCode.length !== 6 }"
            maxlength="6"
            :placeholder="$t('altregistration.verificationCodePlaceholder')"
            required
          />
        </div>
      </form>
      <div class="button-container-regform">
        <button @click="verifyCode" class="submit-btn-regform" :disabled="verificationCode.length !== 6 || isSubmitting">
          {{ $t('altregistration.verifyButton') }}
          <span class="icon-wrapper-regform">
            >
          </span>
        </button>
      </div>
    </div>

    <!-- Popup Modal -->
    <div v-if="showPopup" class="popup-overlay-regform" @click="goToMainPage">
      <div class="popup-content-regform" @click.stop>
        <h3>{{ $t('altregistration.popupTitle') }}</h3>
        <p>{{ $t('altregistration.popupMessage') }}</p>
      </div>
    </div>

    <!-- Error Popup Modal -->
    <div v-if="showErrorPopup" class="popup-overlay-regform" @click="closeErrorPopup">
      <div class="popup-content-regform" @click.stop>
        <h3>{{ $t('altregistration.errorPopupTitle') }}</h3>
        <p>{{ $t('altregistration.errorPopupMessage') }}</p>
        <button @click="closeErrorPopup" class="submit-btn-regform">{{ $t('altregistration.closeButton') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      user: {
        name: '',
        phone: '',
        email: '',
        password: '',
        confirmPassword: ''
      },
      showPopup: false, // Controls visibility of the popup
      showErrorPopup: false,  // Controls error popup visibility
      showVerificationForm: false, // Controls visibility of verification form
      verificationCode: '', // Verification code input
      isSubmitting: false // Tracks if the form is being submitted
    };
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  },
  computed: {
    nameValid() {
      return this.user.name.length > 0;
    },
    phoneValid() {
      const phoneNumber = this.user.phone.replace(/\D/g, ''); // Remove non-numeric characters
      return phoneNumber.length === 12; // Ensure correct phone number length
    },
    emailValid() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(this.user.email);
    },
    passwordValid() {
      const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z]{1,})(?=.*\d).*$/; // At least 1 uppercase, 2 latin symbols
      return this.user.password.length >= 8 && passwordPattern.test(this.user.password);
    },
    passwordsMatch() {
      return this.user.password === this.user.confirmPassword;
    },
    isFormValid() {
      return this.nameValid && this.phoneValid && this.emailValid && this.passwordValid && this.passwordsMatch;
    }
  },
  methods: {
    formatPhoneNumber() {
      let value = this.user.phone;
      if (!value.startsWith('+38')) {
        value = '+38' + value.replace(/[^0-9]/g, '');
      } else {
        value = '+38' + value.substring(3).replace(/[^0-9]/g, '');
      }
      this.user.phone = value;
    },
    registerUser() {
      if (!this.isFormValid) return;

      this.isSubmitting = true; // Disable button during request

      const registrationData = {
        name: this.user.name,
        number: this.user.phone,
        email: this.user.email,
        password: this.user.password
      };

      axios.post('https://api.sushitokash.com.ua/api/users/alt-register', registrationData)
        .then(response => {
          if (response.data === "Registration successful. Please check your email for verification.") {
            this.showVerificationForm = true;
          }
        })
        .catch(error => {
          console.error('Registration failed:', error);
          this.showErrorPopup = true;
        })
        .finally(() => {
          this.isSubmitting = false; // Re-enable button after request completes
        });
    },
    verifyCode() {
      if (this.verificationCode.length !== 6) return;

      this.isSubmitting = true;

      axios
        .post('https://api.sushitokash.com.ua/api/users/alt-verify', {
          emailOrPhone: this.user.email,
          verificationCode: this.verificationCode,
        })
        .then((response) => {
          const token = response.data;
          const expiryTime = 23 * 24 * 60 * 60 * 1000; // 23 days in milliseconds
          this.setTokenWithExpiry('userToken', token, expiryTime);
          this.$router.push('/account'); // Redirect to account page
        })
        .catch((error) => {
          console.error('Verification failed:', error);
          this.showErrorPopup = true;
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    setTokenWithExpiry(key, value, ttl) {
      const now = new Date();
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      };
      localStorage.setItem(key, JSON.stringify(item));
    },
    goToMainPage() {
      this.$router.push('/'); // Navigate to the main page
    },
    closeErrorPopup() {
      this.showErrorPopup = false;  // Close error popup
    }
  }
};
</script>

<style scoped>
.cart-button {
  display: none !important;
}
/* Registration Form CSS */
.registration-form-regform {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 40px;
  color: #fff;
  transition: filter 0.3s ease;
  margin-bottom: 40px;
}

.input-error {
  border: 1px solid red;
}


.registration-form-regform-f {
  padding: 30px;
  border-radius: 10px;
  background-color: #333;
}

.blur-background-regform {
  filter: blur(8px); /* Apply blur effect to the background */
}

h2 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 24px;
  text-align: left;
}

.registration-fields-regform {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.form-group-regform {
  width: 30%;
  margin-bottom: 20px;
}

.form-group-passwords {
  width: 40%;
}

.form-group-regform label {
  display: block;
  margin-bottom: 5px;
  color: #bbb;
  font-size: 14px;
  text-align: left;
}

.form-group-regform .required-regform {
  color: #ff5c5c;
}

input {
  width: 95%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #555;
  color: #fff;
  font-size: 16px;
}

input:focus {
  background-color: #444;
  outline: none;
}

.account-text {
  margin-right: auto;
  color: #fff;
  font-size: 14px;
}

.account-text a {
  color: #a7ff62;
  text-decoration: none;
  margin-left: 5px;
}

.account-text a:hover {
  text-decoration: underline;
}

.button-container-regform {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  gap: 10px;
}


.submit-btn-regform {
  background-color: #a7ff62;
  color: #333;
  border: none;
  padding: 2px 20px; /* Adjusted padding for smaller size */
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px; /* Slightly smaller font */
  display: inline-flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.icon-wrapper-regform {
  background-color: #fff;
  border-radius: 50%;
  color: red;
  padding: 7px; /* Adjusted padding */
  margin-left: 10px; /* Reduced space */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 18px; /* Smaller size */
  height: 18px;
}

.icon-regform {
  width: 12px; /* Smaller icon */
  height: 12px;
}

.submit-btn-regform:hover {
  background-color: #95e055;
}

.submit-btn-regform:hover .icon-wrapper-regform {
  transform: translateX(5px);
  transition: transform 0.3s ease;
}

/* Popup Modal */
.popup-overlay-regform {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content-regform {
  background-color: #444;
  color: #fff;
  padding: 30px;
  border-radius: 15px;
  text-align: center;
  max-width: 500px;
}

.popup-content-regform h3 {
  margin-bottom: 20px;
  font-size: 22px;
  color: #fff;
}

.popup-content-regform p {
  font-size: 16px;
  color: #ccc;
}

/* Responsive styles */
@media (max-width: 768px) {
  .form-group-regform {
    width: 100% !important;
  }
}

/* The same style as your original version */
.error-message {
  color: #ff5c5c;
  font-size: 12px;
}
</style>