<template>
  <div class="popup-overlay" v-if="visible">
    <div class="popup-content">
      <button class="close-btn" @click="closePopup">×</button>
      <div class="popup-header" v-if="isOverloaded">
        <span class="icon">🍣</span>
        <h2>{{ $t('popup.overloaded.title') }}</h2>
        <p>{{ $t('popup.overloaded.message') }}</p>
      </div>
      <div class="popup-header" v-else>
        <span class="icon">🍣</span>
        <h2>{{ $t('popup.sleeping.title') }}</h2>
        <p>{{ $t('popup.sleeping.message') }}</p>
      </div>
      <div class="popup-body">
        <p>{{ $t('popup.schedule.message') }}</p>
        <p class="time">{{ $t('popup.schedule.delivery') }} {{ deliveryStart }} - {{ deliveryEnd }}</p>
        <p class="time">{{ $t('popup.schedule.restaurant') }} {{ restaurantStart }} - {{ restaurantEnd }}</p>
      </div>
      <div class="popup-footer">
        <div class="sushi-icons">
          <span v-for="icon in sushiIcons" :key="icon" class="sushi-icon">{{ icon }}</span>
        </div>
      </div>
    </div>
  </div>
</template>



  

  <!-- <div class="popup-overlay" v-if="visible">
    <div class="popup-content">
      <button class="close-btn" @click="closePopup">×</button>
      <div class="popup-header">
        <span class="icon">🍣</span> 
        <h2>На жаль,<br>Кухня перевантажена!</h2>
        <p>Наразі ми не можемо приймати нові замовлення.</p>
      </div>
      <div class="popup-body">
        <p>Наші кухарі роблять все можливе, щоб відновити прийом замовлень якнайшвидше.</p>
        <p class="time">Очікуйте відновлення роботи:</p>
        <p class="time">Доставка працює з 11:00 до 21:30</p>
        <p class="time">Ресторан працює з 10:00 до 22:00</p>
      </div>
      <div class="popup-footer">
        <div class="sushi-icons">
          <span v-for="icon in sushiIcons" :key="icon" class="sushi-icon">{{ icon }}</span>
        </div>
      </div>
    </div>
  </div> -->




<script>
import axios from 'axios';

export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      isPopupVisible: false, // Control the visibility of the popup
      isOverloaded: false, // To track if the restaurant is overloaded
      sushiIcons: ['🍣', '🍤', '🍙', '🍱'], // Add your own sushi icons here

      // Default times
      deliveryStart: "11:00",
      deliveryEnd: "21:30",
      restaurantStart: "10:00",
      restaurantEnd: "22:00"
    };
  },
  created() {
    this.checkOverloadStatus();
    this.setActiveRestaurantTimes();
    this.checkOperatingHours();
  },
  methods: {
    setActiveRestaurantTimes() {
      // Check for activeRestaurant in localStorage
      const storedRestaurant = localStorage.getItem('activeRestaurant');
      if (storedRestaurant) {
        const activeRestaurant = JSON.parse(storedRestaurant);
        
        // Check if workingHours exists and update delivery and restaurant times
        if (activeRestaurant.workingHours) {
          if (activeRestaurant.workingHours.delivery_time) {
            this.deliveryStart = activeRestaurant.workingHours.delivery_time.start;
            this.deliveryEnd = activeRestaurant.workingHours.delivery_time.end;
          }
          if (activeRestaurant.workingHours.restaurant_hours) {
            this.restaurantStart = activeRestaurant.workingHours.restaurant_hours.start;
            this.restaurantEnd = activeRestaurant.workingHours.restaurant_hours.end;
          }
        }
      }
    },

    checkOperatingHours() {
      const now = new Date().toLocaleString("en-US", { timeZone: "Europe/Kyiv" });
      const currentDate = new Date(now);
      const currentHour = currentDate.getHours();
      const currentMinute = currentDate.getMinutes();

      // Convert deliveryStart and deliveryEnd to hours and minutes for comparison
      const [openingHour, openingMinute] = this.deliveryStart.split(':').map(Number);
      const [closingHour, closingMinute] = this.deliveryEnd.split(':').map(Number);

      // Determine if the current time is outside of operating hours
      if (
        currentHour < openingHour || 
        (currentHour === openingHour && currentMinute < openingMinute) ||
        currentHour > closingHour || 
        (currentHour === closingHour && currentMinute > closingMinute)
      ) {
        this.isPopupVisible = true;
      }
    },

    checkOverloadStatus() {
      const storedRestaurant = localStorage.getItem('activeRestaurant');
      
      if (storedRestaurant) {
        const activeRestaurant = JSON.parse(storedRestaurant);
        
        if (activeRestaurant.overload) {
          this.isOverloaded = true;
          this.isPopupVisible = true; // Show the popup if the active restaurant is overloaded
          return; // Exit the method since the overload status was determined from the active restaurant
        }
      }

      // If activeRestaurant does not exist or is not overloaded, proceed with the original check
      axios
        .get('https://api.sushitokash.com.ua/api/status/ov_s')
        .then((response) => {
          if (response.data === true) {
            this.isOverloaded = true;
            this.isPopupVisible = true; // Show the popup after checking the status
          } else {
            this.isOverloaded = false;
          }
        })
        .catch((error) => {
          console.error('Error fetching overload status:', error);
        });
    },


    closePopup() {
      this.$emit('close-popup');
    },
  }
};

</script>

<style scoped>
.popup-overlay {
    color: #c1b3b3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  text-align: center;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}

.popup-header h2 {
  font-size: 24px;
  margin: 10px 0;
}

.popup-header p {
  color: #7f7f7f;
  margin: 5px 0;
}

.popup-body .time {
  font-size: 18px;
  margin: 10px 0;
}

.preorder-btn {
  background-color: #5aabf0;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.sushi-icons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.sushi-icon {
  font-size: 24px;
}

.popup-footer {
  margin-top: 20px;
}
</style>
