<template>
  <div :class="{'blur-background-regform': showPopup}" class="registration-form-regform">
    <div v-if="showVerificationForm" class="registration-form-regform-f">
      <h2>{{ $t('altlogin.verificationTitle') }}</h2>
      <p>{{ $t('altlogin.verificationInstruction') }}</p>
      <form @submit.prevent="verifyCode" class="registration-fields-regform">
        <div class="form-group-regform">
          <label for="verificationCode">
            {{ $t('altlogin.verificationCodeLabel') }}<span class="required-regform">*</span>
          </label>
          <input
            type="text"
            id="verificationCode"
            v-model="verificationCode"
            :class="{ 'input-error': verificationCode.length !== 6 }"
            maxlength="6"
            :placeholder="$t('altlogin.verificationCodePlaceholder')"
            required
          />
        </div>
      </form>
      <div class="button-container-regform">
        <button @click="verifyCode" class="submit-btn-regform" :disabled="verificationCode.length !== 6 || isSubmitting">
          {{ $t('altlogin.confirmButton') }}
          <span class="icon-wrapper-regform">></span>
        </button>
      </div>
    </div>

    <div v-else class="registration-form-regform-f">
      <h2>{{ $t('altlogin.loginTitle') }}</h2>
      <form @submit.prevent="loginUser" class="registration-fields-regform">
        <div class="form-group-regform">
          <label for="contact">
            {{ $t('altlogin.contactLabel') }}<span class="required-regform">*</span>
          </label>
          <input
            type="text"
            id="contact"
            v-model="user.contact"
            :class="{ 'input-error': !contactValid }"
            required
            maxlength="40"
            :placeholder="$t('altlogin.contactLabel')"
          />
        </div>

        <div class="form-group-regform">
          <label for="password">
            {{ $t('altlogin.passwordLabel') }}<span class="required-regform">*</span>
          </label>
          <input
            type="password"
            id="password"
            v-model="user.password"
            :class="{ 'input-error': !passwordValid }"
            required
            minlength="6"
            :placeholder="$t('altlogin.passwordPlaceholder')"
          />
        </div>
      </form>

      <div class="forgot-password">
        <a href="#/reset-password">{{ $t('altlogin.forgotPassword') }}</a>
      </div>

      <div class="button-container-regform">
        <div class="account-text">
          <span>{{ $t('altlogin.noAccountText') }}</span>
          <a href="#/registration">{{ $t('altlogin.registerLink') }}</a>
        </div>
        <button @click="loginUser" class="submit-btn-regform" :disabled="!contactValid || !passwordValid || isSubmitting">
          {{ $t('altlogin.loginButton') }}
          <span class="icon-wrapper-regform">></span>
        </button>
      </div>
    </div>

    <div v-if="showErrorPopup" class="popup-overlay-regform" @click="closeErrorPopup">
      <div class="popup-content-regform" @click.stop>
        <h3>{{ errorMessage }}</h3>
        <p>{{ $t('altlogin.errorMessage') }}</p>
        <button @click="closeErrorPopup" class="submit-btn-regform">{{ $t('altlogin.closeButton') }}</button>
      </div>
    </div>

    <div v-if="showPopup" class="popup-overlay-regform" @click="goToMainPage">
      <div class="popup-content-regform" @click.stop>
        <h3>{{ $t('altlogin.successMessageTitle') }}</h3>
        <p>{{ $t('altlogin.successMessage') }}</p>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  data() {
    return {
      user: {
        contact: '', // Single input for either phone or email
        password: '', // Password input
      },
      verificationCode: '', // Stores the entered verification code
      showVerificationForm: false, // Toggles between login and verification form
      showPopup: false,
      isSubmitting: false, // To disable button after submission
      showErrorPopup: false, // Controls error popup visibility
      errorMessage: '', // Holds error message for error popup
    };
  },
  computed: {
    contactValid() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phonePattern = /^\+38\d{10}$/; // Ukrainian phone number format
      return emailPattern.test(this.user.contact) || phonePattern.test(this.user.contact);
    },
    passwordValid() {
      return this.user.password.length >= 6; // Ensure password is at least 6 characters long
    },
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  },
  methods: {
    loginUser() {
      if (!this.contactValid || !this.passwordValid) return;

      this.isSubmitting = true; // Disable the button during the request

      axios
        .post('https://api.sushitokash.com.ua/api/users/alt-login', {
          emailOrPhone: this.user.contact,
          password: this.user.password, // Include password in the request
        })
        .then((response) => {
          if (response.data === 'Verification code sent to your email.') {
            this.showVerificationForm = true; // Switch to the verification form
          }
        })
        .catch((error) => {
          const responseStatus = error.response ? error.response.status : null;
          if (responseStatus === 401) {
            const errorMessage = error.response.data;
            if (errorMessage === 'Invalid password.') {
              this.errorMessage = 'Невірний пароль.';
            } else if (errorMessage === 'Invalid email or phone number.') {
              this.errorMessage = 'Невірний e-mail або номер телефону.';
            } else {
              this.errorMessage = 'Авторизація не вдалася. Спробуйте ще раз.';
            }
          } else {
            this.errorMessage = 'Сталася помилка. Спробуйте ще раз пізніше.';
          }
          this.showErrorPopup = true;
        })
        .finally(() => {
          this.isSubmitting = false; // Re-enable the button after the request
        });
    },
    verifyCode() {
      if (this.verificationCode.length !== 6) return;

      this.isSubmitting = true;

      axios
        .post('https://api.sushitokash.com.ua/api/users/alt-verify', {
          emailOrPhone: this.user.contact,
          verificationCode: this.verificationCode,
        })
        .then((response) => {
          const token = response.data;
          const expiryTime = 23 * 24 * 60 * 60 * 1000; // 23 days in milliseconds
          this.setTokenWithExpiry('userToken', token, expiryTime);
          this.$router.push('/account'); // Redirect to account page
        })
        .catch((error) => {
          const responseStatus = error.response ? error.response.status : null;
          if (responseStatus === 401) {
            this.errorMessage = 'Невірний код підтвердження.';
          } else if (responseStatus === 404) {
            this.errorMessage = 'Користувач не знайдений.';
          } else {
            this.errorMessage = 'Сталася помилка. Спробуйте ще раз пізніше.';
          }
          this.showErrorPopup = true;
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    setTokenWithExpiry(key, value, ttl) {
      const now = new Date();
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      };
      localStorage.setItem(key, JSON.stringify(item));
    },
    goToMainPage() {
      this.$router.push('/'); // Navigate to the main page
    },
    closeErrorPopup() {
      this.showErrorPopup = false; // Close error popup
    },
  },
};
</script>

<style scoped>
/* Registration Form CSS */
.registration-form-regform {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 40px;
  color: #fff;
  transition: filter 0.3s ease;
  margin-bottom: 40px;
}

.input-error {
  border: 1px solid red;
}


.registration-form-regform-f {
  padding: 30px;
  border-radius: 10px;
  background-color: #333;
}

.blur-background-regform {
  filter: blur(8px); /* Apply blur effect to the background */
}

h2 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 24px;
  text-align: left;
}

.registration-fields-regform {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.form-group-regform {
  width: 95%;
  margin-bottom: 20px;
}

.form-group-regform label {
  display: block;
  margin-bottom: 5px;
  color: #bbb;
  font-size: 14px;
  text-align: left;
}

.form-group-regform .required-regform {
  color: #ff5c5c;
}

input {
  width: 95%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #555;
  color: #fff;
  font-size: 16px;
}

input:focus {
  background-color: #444;
  outline: none;
}

.account-text {
  margin-right: auto;
  color: #fff;
  font-size: 14px;
}

.account-text a {
  color: #a7ff62;
  text-decoration: none;
  margin-left: 5px;
}

.account-text a:hover {
  text-decoration: underline;
}

.button-container-regform {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;
}


.submit-btn-regform {
  background-color: #a7ff62;
  color: #333;
  border: none;
  padding: 2px 20px; /* Adjusted padding for smaller size */
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px; /* Slightly smaller font */
  display: inline-flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.icon-wrapper-regform {
  background-color: #fff;
  border-radius: 50%;
  color: red;
  padding: 7px; /* Adjusted padding */
  margin-left: 10px; /* Reduced space */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 18px; /* Smaller size */
  height: 18px;
}

.icon-regform {
  width: 12px; /* Smaller icon */
  height: 12px;
}

.submit-btn-regform:hover {
  background-color: #95e055;
}

.submit-btn-regform:hover .icon-wrapper-regform {
  transform: translateX(5px);
  transition: transform 0.3s ease;
}

/* Popup Modal */
.popup-overlay-regform {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content-regform {
  background-color: #444;
  color: #fff;
  padding: 30px;
  border-radius: 15px;
  text-align: center;
  max-width: 500px;
}

.popup-content-regform h3 {
  margin-bottom: 20px;
  font-size: 22px;
  color: #fff;
}

.popup-content-regform p {
  font-size: 16px;
  color: #ccc;
}

/* Responsive styles */
@media (max-width: 768px) {
  .form-group-regform {
    width: 100%;
  }
}


.forgot-password {
  text-align: right;
  margin-bottom: 10px;
}

.forgot-password a {
  color: #a7ff62;
  text-decoration: none;
}

.forgot-password a:hover {
  text-decoration: underline;
}
</style>

