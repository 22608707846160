<template>
  <div class="reset-password-form">
    <!-- Case 1: User requests password reset -->
    <div v-if="!token && !resetSuccess">
      <h2>{{ $t('resetPassword.title') }}</h2>
      <form @submit.prevent="requestResetPassword" class="reset-password-fields">
        <div class="form-group">
          <label for="emailOrPhone">{{ $t('resetPassword.emailOrPhoneLabel') }}<span class="required">*</span></label>
          <input
            type="text"
            id="emailOrPhone"
            v-model="emailOrPhone"
            :class="{ 'input-error': !contactValid && emailOrPhone !== '' }"
            required
            :placeholder="$t('resetPassword.emailOrPhoneLabel')"
          />
          <p v-if="!contactValid && emailOrPhone !== ''" class="error-message">{{ $t('resetPassword.emailOrPhoneError') }}</p>
          <p v-if="showError" class="error-message">{{ errorMessage }}</p>
        </div>
        <button @click="requestResetPassword" class="submit-btn" :disabled="!contactValid || isSubmitting">
          {{ $t('resetPassword.sendRequest') }}
        </button>
      </form>
    </div>

    <!-- Case 2: User resets password using token -->
    <div v-if="token && !resetSuccess">
      <h2>{{ $t('resetPassword.title') }}</h2>
      <form @submit.prevent="resetPassword" class="reset-password-fields">
        <div class="form-group">
          <label for="password">{{ $t('resetPassword.passwordLabel') }}<span class="required">*</span></label>
          <input
            type="password"
            id="password"
            v-model="password"
            :class="{ 'input-error': !passwordValid && password !== '' }"
            required
            :placeholder="$t('resetPassword.passwordLabel')"
          />
          <p v-if="password && !passwordValid" class="error-message">
            {{ $t('resetPassword.passwordError') }}
          </p>
        </div>
        <div class="form-group">
          <label for="confirmPassword">{{ $t('resetPassword.confirmPasswordLabel') }}<span class="required">*</span></label>
          <input
            type="password"
            id="confirmPassword"
            v-model="confirmPassword"
            :class="{ 'input-error': !passwordsMatch && confirmPassword !== '' }"
            required
            :placeholder="$t('resetPassword.confirmPasswordLabel')"
          />
          <p v-if="!passwordsMatch && confirmPassword !== ''" class="error-message">{{ $t('resetPassword.passwordsMismatchError') }}</p>
        </div>
        <button @click="resetPassword" class="submit-btn" :disabled="!passwordValid || !passwordsMatch || isSubmitting">
          {{ $t('resetPassword.title') }}
        </button>
        <p v-if="showError" class="error-message">{{ errorMessage }}</p>
      </form>
    </div>

    <!-- Success Message after reset -->
    <div v-if="resetSuccess">
      <h2>{{ $t('resetPassword.resetSuccessMessage') }}</h2>
      <!-- <p>Ви будете перенаправлені до вашого акаунту.</p> -->
    </div>

    <!-- Success Popup for password reset request -->
    <div v-if="showPopup" class="popup-overlay-regform" @click="goToMainPage">
      <div class="popup-content-regform" @click.stop>
        <h3>{{ $t('resetPassword.checkEmailMessage') }}</h3>
        <!-- <p>Вам на електронну пошту надіслано посилання для відновлення паролю</p> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      emailOrPhone: '',
      token: null, // Store the reset token
      password: '',
      confirmPassword: '',
      showError: false,
      errorMessage: '',
      isSubmitting: false,
      resetSuccess: false, // Show success message on password reset
      showPopup: false // Controls the visibility of the popup
    };
  },
  computed: {
    contactValid() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phonePattern = /^\+38\d{10}$/; // Ukrainian phone number format
      return emailPattern.test(this.emailOrPhone) || phonePattern.test(this.emailOrPhone);
    },
    passwordValid() {
      const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z]{1,})(?=.*\d).*$/; // At least 1 uppercase, 2 latin symbols
      return this.password.length >= 8 && passwordPattern.test(this.password);
    },
    passwordsMatch() {
      return this.password === this.confirmPassword;
    },
  },
  created() {
    // Check for token in URL
    const hashParams = window.location.hash.split('?')[1];
    const urlParams = new URLSearchParams(hashParams);
    this.token = urlParams.get('tk');

    // If token exists, remove it from URL
    if (this.token) {
      urlParams.delete('tk');
      const newHash = `${window.location.hash.split('?')[0]}${urlParams.toString() ? '?' + urlParams.toString() : ''}`;
      history.replaceState(null, '', newHash);
    }
    
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

  },
  methods: {
    requestResetPassword() {
      if (!this.contactValid) return;

      this.isSubmitting = true;
      this.showError = false;

      axios
        .post('https://api.sushitokash.com.ua/api/users/request-reset-password', { emailOrPhone: this.emailOrPhone })
        .then((response) => {
          if (response.data === 'success') {
            this.showPopup = true; // Show success popup after request
          }
        })
        .catch((error) => {
          const responseStatus = error.response ? error.response.status : null;
          if (responseStatus === 401) {
            this.errorMessage = 'Невірний e-mail або номер телефону.';
          } else {
            this.errorMessage = 'Помилка. Спробуйте ще раз пізніше.';
          }
          this.showError = true;
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    resetPassword() {
      if (!this.passwordValid || !this.passwordsMatch) return;

      this.isSubmitting = true;
      this.showError = false;

      axios
        .put('https://api.sushitokash.com.ua/api/users/reset-password', { password: this.password }, {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
        .then((response) => {
          if (response.data === 'Password has been successfully reset.') {
            const token = this.token;
            const expiryTime = 23 * 24 * 60 * 60 * 1000; // 23 days in milliseconds
            this.setTokenWithExpiry('userToken', token, expiryTime);
            this.resetSuccess = true;
            setTimeout(() => this.$router.push('/account'), 2000); // Redirect to account page after 2 seconds
          }
        })
        .catch((error) => {
          const responseStatus = error.response ? error.response.status : null;
          if (responseStatus === 404) {
            this.errorMessage = 'Користувач не знайдений.';
          } else {
            this.errorMessage = 'Сталася помилка. Спробуйте ще раз пізніше.';
          }
          this.showError = true;
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    setTokenWithExpiry(key, value, ttl) {
      const now = new Date();
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      };
      localStorage.setItem(key, JSON.stringify(item));
    },
    goToMainPage() {
      this.$router.push('/'); // Navigate to the main page
    }
  },
};
</script>

<style scoped>
/* Styles for reset password form */
.reset-password-form {
  max-width: 400px;
  min-width: 400px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: #333;
  color: white;
  border-radius: 10px;
}

.reset-password-fields {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #555;
  color: white;
}

input:focus {
  outline: none;
}

.submit-btn {
  background-color: #a7ff62;
  color: #333;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #95e055;
}

.input-error {
  border: 1px solid red;
}

.error-message {
  color: #ff5c5c;
  font-size: 12px;
}

/* Popup Styles */
.popup-overlay-regform {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content-regform {
  background-color: #444;
  color: #fff;
  padding: 30px;
  border-radius: 15px;
  text-align: center;
  max-width: 500px;
}

.popup-content-regform h3 {
  margin-bottom: 20px;
  font-size: 22px;
  color: #fff;
}

.popup-content-regform p {
  font-size: 16px;
  color: #ccc;
}

@media (max-width: 500px) {
  .reset-password-form {
      min-width: 100px;
    width: 95%;
  }
}
</style>