<template>
  <section class="promo-section">
    <h2>{{ $t('promoSection.header') }}</h2>
    <swiper
      :slides-per-view="1"
      :space-between="30"
      :breakpoints="breakpoints"
      :modules="modules"
      navigation
      pagination
      class="mySwiper"
      ref="mySwiper"
    >
      <!-- Displaying Active Sales from localStorage if available -->
      <template v-if="hasSales">
        <swiper-slide 
          v-for="(sale) in activeSales" 
          :key="sale.id" 
          class="swiper-slide"
        >
          <div class="promo-item">
            <img :src="sale.image" :alt="saleName(sale)" />
            <div class="promo-text">
              <h3>{{ saleName(sale) }}</h3>
              <p>{{ saleDescription(sale) }}</p>
            </div>
          </div>
        </swiper-slide>
      </template>

      <!-- Display Default Promotions if no Active Sales -->
      <template v-else>
        <swiper-slide 
          v-for="(item, index) in items" 
          :key="index" 
          class="swiper-slide"
        >
          <div class="promo-item">
            <img :src="item.image" :alt="$t(`promoSection.items[${index}].title`)" />
            <div class="promo-text">
              <h3>{{ $t(`promoSection.items[${index}].title`) }}</h3>
              <p>{{ $t(`promoSection.items[${index}].description`) }}</p>
            </div>
          </div>
        </swiper-slide>
      </template>

      <div class="swiper-pagination"></div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </swiper>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper/modules';
import { ref } from 'vue';

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    const mySwiper = ref(null);

    const slideNext = () => mySwiper.value.swiper.slideNext();
    const slidePrev = () => mySwiper.value.swiper.slidePrev();

    return {
      mySwiper,
      slideNext,
      slidePrev,
      modules: [Navigation, Pagination]
    };
  },
  data() {
    return {
      breakpoints: {
        600: { slidesPerView: 1, spaceBetween: 10 },
        900: { slidesPerView: 2, spaceBetween: 20 },
        1200: { slidesPerView: 3, spaceBetween: 30 }
      },
      items: [
        {
          image: require('@/assets/sales/take_away.png'),
          title: "Забери сам",
          description: "При замовленні онлайн забираєте замовлення самостійно 10% знижка"
        },
        {
          image: require('@/assets/sales/friday_15.jpeg'),
          title: "МЕГА АКЦІЯ",
          description: "Тільки в цю п’ятницю замов роли та отримай -15% на все меню!"
        },
        {
          image: require('@/assets/sales/saturday_futomaki.jpeg'),
          title: "Суботня акція",
          description: "Зроби замовлення від 800 грн та отримай футомакі з лососем"
        },
        {
          image: require('@/assets/sales/thursday_bonus_boul.jpeg'),
          title: "Лише у четвер!",
          description: "При замовленні на 500 грн – фіш боул у подарунок!"
        },
        {
          image: require('@/assets/sales/happy_birthday.png'),
          title: "На День Народження",
          description: "При пред'явленні документу з підтвердженням дати народження 20%"
        },
        {
          image: require('@/assets/sales/student.jpeg'),
          title: "Студентський бум",
          description: "При пред'явленні студентського квитка 15% знижка"
        }
      ],
      activeSales: [],
      hasSales: false
    };
  },
  created() {
    const storedSalesDetails = localStorage.getItem('salesDetails');
    if (storedSalesDetails) {
      const sales = JSON.parse(storedSalesDetails);
      this.activeSales = sales
        .filter(sale => sale.status === 'ACTIVE')
        .map(sale => {
          try {
            const imagePath = require(`@/assets/${sale.imgPath}`);
            return { ...sale, image: imagePath };
          } catch (e) {
            console.error(`Image not found for sale ID ${sale.id}, using placeholder.`);
            return { ...sale, image: require('@/assets/sales/bonus.png') };
          }
        });

      this.hasSales = this.activeSales.length > 0;
    }
  },
  methods: {
    saleName(sale) {
      return this.$i18n.locale === 'uk' ? sale.nameUa : sale.nameEn;
    },
    saleDescription(sale) {
      return this.$i18n.locale === 'uk' ? sale.descriptionUa : sale.descriptionEn;
    }
  }
};
</script>

<style scoped>
.promo-section {
  padding: 20px;
  text-align: center;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure slides take full height */
}

.promo-item {
  background-color: #333;
  color: #E9E9E9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Ensure the promo item takes full height */
  width: 100%; /* Ensure promo items fill the slide */
  max-width: 300px; /* Adjust as needed to fit your design */
  box-sizing: border-box;
}

.promo-item img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: 200px; /* Adjust height as needed */
  object-fit: cover;
}

.promo-text {
  margin-top: auto;
  text-align: left; /* Ensure the text remains left-aligned */
}

.swiper-button-prev {
  background-image: url('@/assets/left_arrow.svg'); /* Path to your SVG file */
}

.swiper-button-next {
  background-image: url('@/assets/right_arrow.svg'); /* Path to your SVG file */
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: ''; /* Remove default icons */
  /* background-image: url('@/assets/right_arrow.svg'); */
}

.swiper-button-next,
.swiper-button-prev {
  color: #e91e63;
  display: none;
}

.swiper-pagination-bullet {
  background: #e91e63;
}


</style>


