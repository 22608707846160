<template>
  <section class="promo-section">
    <h2>{{ $t('promoSection.header') }}</h2>
    <div class="promo-grid">
      <!-- Display sales from localStorage if available -->
      <template v-if="hasSales">
        <div
          v-for="(sale, index) in activeSales"
          :key="sale.id"
          :class="`promo-item item${index + 1}`"
        >
          <img :src="sale.image" :alt="saleName(sale)" />
          <h3>{{ saleName(sale) }}</h3>
          <p>{{ saleDescription(sale) }}</p>
        </div>
      </template>
      <!-- Else display default promotions -->
      <template v-else>
        <div
          v-for="(item, index) in items"
          :key="index"
          :class="`promo-item item${index + 1}`"
        >
          <img :src="item.image" :alt="$t(`promoSection.items.item${index + 1}.title`)" />
          <h3>{{ $t(`promoSection.items.item${index + 1}.title`) }}</h3>
          <p>{{ $t(`promoSection.items.item${index + 1}.description`) }}</p>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutUs",
  data() {
    return {
      salesDetails: [],
      activeSales: [],
      hasSales: false,
      items: [
        { image: require('@/assets/sales/take_away.png') },
        { image: require('@/assets/sales/friday_15.jpeg') },
        { image: require('@/assets/sales/saturday_futomaki.jpeg') },
        { image: require('@/assets/sales/thursday_bonus_boul.jpeg') },
        { image: require('@/assets/sales/happy_birthday.png') },
        { image: require('@/assets/sales/student.jpeg') },
        // { image: require('@/assets/sales/instagram_repost.jpeg')},
        // { image: require('@/assets/sales/fisht_day.jpeg')},
        // { image: require('@/assets/sales/happy_hour.jpeg')},
        // { image: require('@/assets/sales/friend.jpeg')},
        // { image: require('@/assets/sales/bonus.jpeg')},
        // { image: require('@/assets/sales/first_time.jpeg')},
        // { image: require('@/assets/sales/one_plus_one.jpeg')},
      ],
    };
  },
  created() {
    // Load salesDetails from localStorage
    const storedSalesDetails = localStorage.getItem('salesDetails');
    const activeRestaurant = JSON.parse(localStorage.getItem('activeRestaurant'));

    if (storedSalesDetails && activeRestaurant) {
      const sales = JSON.parse(storedSalesDetails);

      // Filter active sales related to the activeRestaurant and add image paths
      this.activeSales = sales
        .filter(sale => sale.status === 'ACTIVE' && sale.restaurantId === activeRestaurant.id)
        .map(sale => {
          // Ensure imgPath exists and is a string
          if (!sale.imgPath || typeof sale.imgPath !== 'string') {
            console.warn(`Invalid imgPath for sale ID ${sale.id}`);
            return null;
          }

          // Extract file extension
          const extension = sale.imgPath.substring(sale.imgPath.lastIndexOf('.')).toLowerCase();
          const validImageExtensions = ['.png', '.jpg', '.jpeg', '.gif'];

          // Validate file extension
          if (!validImageExtensions.includes(extension)) {
            console.warn(`Skipping sale ID ${sale.id} due to invalid image extension: ${sale.imgPath}`);
            return null;
          }

          let imagePath = '';
          try {
            imagePath = require(`@/assets/${sale.imgPath}`);
          } catch (e) {
            console.error(`Image not found for sale ID ${sale.id}: ${sale.imgPath}`);
            // Use the correct path to your placeholder image
            imagePath = require('@/assets/sales/bonus.png'); // Adjust if needed
          }

          return {
            ...sale,
            image: imagePath
          };
        })
        .filter(sale => sale !== null); // Remove null entries


      if (this.activeSales.length > 0) {
        this.hasSales = true;
      }
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  },
  watch: {
    '$i18n.locale'() {
      // UI updates automatically due to reactivity
    }
  },
  methods: {
    saleName(sale) {
      return this.$i18n.locale === 'uk' ? sale.nameUa : sale.nameEn;
    },
    saleDescription(sale) {
      return this.$i18n.locale === 'uk' ? sale.descriptionUa : sale.descriptionEn;
    }
  }
};
</script>

<style scoped>
.promo-section {
  padding: 20px;
  padding-top: 40px;
  text-align: center;
}

.promo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.promo-item {
  background-color: #333;
  color: #E9E9E9;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.promo-item img {
  width: 80%;
  height: auto;
  margin: 0 auto 20px;
}

.promo-item h3,
.promo-item p {
  width: 100%;
  text-align: left;
}

@media (max-width: 768px) {
  .promo-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (max-width: 600px) {
  .promo-grid {
    grid-template-columns: 1fr;
  }
}
</style>
