import { createRouter, createWebHashHistory } from 'vue-router';
import HelloWorld from './components/MainMenu.vue';
import About from './components/About.vue';
import Sales from './components/Sales.vue';
import SushiItem from './components/SushiItem.vue';
import Polityka from './components/Polityka.vue';
import Contact from './components/Contact.vue';
import UserAccount from './components/UserAccount.vue';
import RegistrationForm from './components/RegistrationForm.vue';
import AltRegistration from './components/AltRegistration.vue';
import LoginForm from './components/LoginForm.vue';
import AltLogin from './components/AltLogin.vue';
import ResetPassword from './components/ResetPassword.vue';
import PwaInstruction from './components/PwaInstruction.vue';

const routes = [
  { path: '/', name: 'home', component: HelloWorld },
  { path: '/about', name: 'about', component: About },
  { path: '/sales', name: 'sales', component: Sales },
  { path: '/polityka', name: 'polityka', component: Polityka },
  { path: '/contact', name: 'contact', component: Contact },
  { path: '/account', name: 'account', component: UserAccount },
  { path: '/sign-up', name: 'sign-up', component: RegistrationForm },
  { path: '/login', name: 'login', component: AltLogin },
  { path: '/reset-password', name: 'reset-password', component: ResetPassword },
  { path: '/registration', name: 'registration', component: AltRegistration },
  { path: '/sign-in', name: 'sign-in', component: LoginForm },
  { path: '/installation-instructions', name: 'installation-instructions', component: PwaInstruction },
  {
    path: '/sushi-item/:article',
    name: 'SushiItem',
    component: SushiItem,
    props: true
  }
 
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
