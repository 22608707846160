<template>
  <section class="app-section" id="application-section">
    <h2>{{ $t('appSection.header') }}</h2>
    <div class="app-download">
      <div class="app-info">
        <h3>{{ $t('appSection.header2') }}</h3>
        <p>{{ $t('appSection.description') }}</p>
        <button v-if="deferredPrompt" @click="installPWA()">
          {{ $t('appSection.downloadButton') }}
          <img src=".././assets/download_icon.png" alt="Plus Icon" />
        </button>
        <button v-else @click="showInstallPWAPopup()">
          {{ $t('appSection.downloadButton') }}
          <img src=".././assets/download_icon.png" alt="Plus Icon" />
        </button>
      </div>
      <img src=".././assets/iso_download.png" alt="App Image" class="app-image" />
    </div>

    <div v-if="showPopup" class="custom-install-popup">
      <div class="popup-content">
        <h3>{{ $t('appSection.popupTitle') }}</h3>
        <p v-if="isIOS" v-html="$t('appSection.popupIOS')"></p>
        <p v-else>{{ $t('appSection.popupBrowser') }}</p>
        <button @click="closePopup">{{ $t('appSection.close') }}</button>
      </div>
    </div>

    <section class="promo-section instructions">
      <h2>{{ $t('instructions.ios') }}</h2>
      <div class="instructions-grid">
        <div class="instruction-item">
          <img src="img/pwa_instractions/qr_sushitokash.jpg" alt="QR Code iOS" />
          <p v-html="$t('instructions.step1')"></p>
        </div>
        <div class="instruction-item">
          <img src="img/pwa_instractions/ios_preview.jpg" alt="Safari Browser" />
          <p v-html="$t('instructions.step2')"></p>
        </div>
        <div class="instruction-item">
          <img src="img/pwa_instractions/ios_buttom_nav.jpg" alt="Safari Share" />
          <p v-html="$t('instructions.step3')"></p>
        </div>
        <div class="instruction-item">
          <img src="img/pwa_instractions/ios_browser_setting.jpg" alt="Add to Home iOS" />
          <p v-html="$t('instructions.step4')"></p>
        </div>
      </div>

      <div class="video-link">
        <a href="video/installation_sushitokash.mp4" class="watch-video-link">
          {{ $t('watchVideo') }}
        </a>
      </div>

      <h2>{{ $t('instructions.android') }}</h2>
      <div class="instructions-grid">
        <div class="instruction-item">
          <img src="img/pwa_instractions/qr_sushitokash.jpg" alt="QR Code Android" />
          <p v-html="$t('instructions.androidStep1')"></p>
        </div>
        <div class="instruction-item">
          <img src="img/pwa_instractions/android_preview.jpg" alt="Android Browser" />
          <p v-html="$t('instructions.androidStep2')"></p>
        </div>
        <div class="instruction-item">
          <img src="img/pwa_instractions/android_browser_setting.jpg" alt="Add to Home Android" />
          <p v-html="$t('instructions.androidStep3')"></p>
        </div>
      </div>

      <div class="video-link">
        <a href="video/installation_sushitokash.mp4" class="watch-video-link">
          {{ $t('watchVideo') }}
        </a>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name: "About-us",
  props: {
    msg: String,
  },
  data() {
    return {
      showPopup: false,
      deferredPrompt: null,
    }
  },
  mounted(){
      this.isIOS = this.checkIfIOS();
      window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent the mini-infobar from appearing on mobile
        console.log(`'beforeinstallprompt' event was fired.`);
        e.preventDefault();
        // Stash the event so it can be triggered later
        this.deferredPrompt = e;
        // Show an install promotion button or update UI
        //this.showInstallPromotion();
        });
  },
  methods: {
    checkIfIOS() {
      const userAgent = window.navigator.userAgent;
      return /iPhone|iPad|iPod/.test(userAgent);
    },
    showInstallPWAPopup() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },
    promptInstall() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
          } else {
            console.log('User dismissed the install prompt');
          }
          this.deferredPrompt = null;
        });
      }
    },
    installPWA() {
      console.log("install PWa")
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          this.deferredPrompt = null;
        });
      }
    },
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  },
};
</script>

<style scoped>
.promo-section {
  padding: 20px;
  padding-top: 40px;
  text-align: center;
}

.promo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
}

.promo-item {
  background-color: #333;
  color: #E9E9E9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.promo-item img {
  width: 80%;
  height: auto;
  margin: 0 auto 20px;
}

.promo-item h3, .promo-item p {
  width: 100%;
  text-align: left;
}

@media (max-width: 768px) {
  .promo-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (max-width: 600px) {
  .promo-grid {
    grid-template-columns: 1fr;
  }
}


.instructions {
  padding: 20px;
  padding-top: 40px;
  text-align: center;
}

.instructions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.instruction-item {
  background-color: #333;
  color: #E9E9E9;
  padding: 10px 10px 20px 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.instruction-item img {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.instruction-item p {
  margin: 0;
  font-size: 16px;
  color: #E9E9E9;
  text-align: center;
}

.video-link {
  margin-top: 20px;
}

.watch-video-link {
  font-size: 18px;
  text-decoration: none;
  color: #88c057;
}

.watch-video-link:hover {
  text-decoration: underline;
}

/* Responsive design adjustments */
@media (max-width: 600px) {
  .instructions-grid {
    grid-template-columns: 1fr;
  }

  .instruction-item {
    padding: 15px;
  }

  .instruction-item p {
    font-size: 14px;
  }
}


/* Add the custom popup styling */
.custom-install-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color:black;
  z-index: 1000;
}

.custom-install-popup a {
  color: black
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  text-align: center;
}

.popup-content h3 {
  margin-bottom: 20px;
}

.popup-content button {
  background-color: #88c057;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.popup-content button:hover {
  background-color: #7bb249;
}


</style>
